import React from 'react'
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import { Button } from 'antd'
import { Link } from 'react-router-dom';
import RolesHelper from '../services/permisions/RolesHelper'
import { Auth } from '../services/Auth';

const NotFound = props => {
  // useEffect(() => {
  //   Auth.invalidateSession();
  // });
  if (RolesHelper.isAffilateUser()) {
    Auth.invalidateSession();
  }

  return (
    <div className='nf--container'>
      <div className='nf--content'>
        <h2>{props.intl.messages.notFoundHeader}</h2>
        <p>{props.intl.messages.notFoundTxt}</p>

        <Link to={RolesHelper.getRedirectPage()}>
          <Button type="primary" htmlType="submit" className="button" >
            {props.intl.messages.goToHome}
          </Button>
        </Link>
      </div>
    </div>
  )
}

const mapDispatchToProps = (dispatch, props) => {
  return ({
    dispatch,
    props
  })
}

export default injectIntl(connect(
  null,
  mapDispatchToProps
)(NotFound));
import { ActionType } from '../../../constants/ActionTypes';

export default function storyBlocksReducer(state = {}, action = {}) {
    switch (action.type) {
        case ActionType.GET_STORY_BLOCKS_BEGIN:
        case ActionType.MOVE_STORY_BLOCKS_VISIBILITY_BEGIN:
        case ActionType.DELETE_STORY_BLOCK_BEGIN:
            return {
                ...state,
                inProcess: true,
                saving: false
            }
        case ActionType.EDIT_STORY_BLOCK_BEGIN:
        case ActionType.ADD_STORY_BLOCKS_BEGIN:
            return {
                ...state,
                saving: true
            }
        case ActionType.GET_STORY_BLOCKS_SUCCESS:
            return {
                ...state,
                storyBlockData: action.payload,
                inProcess: false
            }
        case ActionType.GET_STORY_BLOCKS_ERROR:
        case ActionType.CHANGE_STORY_BLOCKS_SETTINGS_ERROR:
        case ActionType.MOVE_STORY_BLOCKS_VISIBILITY_SUCCESS:
        case ActionType.MOVE_STORY_BLOCKS_VISIBILITY_ERROR:
        case ActionType.DELETE_STORY_BLOCK_SUCCESS:
        case ActionType.DELETE_STORY_BLOCK_ERROR:
            return {
                ...state,
                inProcess: false
            }

        case ActionType.GET_STORY_BLOCKS_SETTINGS_SUCCESS:
        case ActionType.CHANGE_STORY_BLOCKS_SETTINGS_SUCCESS:
            return {
                ...state,
                inProcess: false,
                settings: {
                    ...state.settings,
                    ...action.payload
                }
            }
        case ActionType.CHANGE_STORY_BLOCKS_VISIBILITY_SETTINGS_SUCCESS:
            return {
                ...state,
                inProcess: false,
                storyBlockData: state.storyBlockData.map(block => {
                    return block.id === action.payload.id ? {
                        ...block,
                        isEnabled: action.payload.isEnabled
                    } : block
                })
            }
        case ActionType.CHANGE_STORY_IMAGE_SETTINGS_SUCCESS:
            return {
                ...state,
                inProcess: false,
                storyBlockData: state.storyBlockData.map(block => {
                    return {
                        ...block,
                        stories: block.stories.map(story => {
                            return story.id === action.payload.id ? {
                                ...story,
                                isEnabled: action.payload.isEnabled
                            } : story
                        })
                    }
                })
            }

        case ActionType.GET_STORY_SECTIONS_SUCCESS:
            return {
                ...state,
                sectionsLoading: false,
                sections: action.payload
            }
        case ActionType.GET_STORY_SECTIONS_BEGIN:
        case ActionType.GET_STORY_GET_SECTIONS_ERROR:
            return {
                ...state,
                sectionsLoading: true
            }
        case ActionType.ADD_STORY_BLOCKS_ERROR:
        case ActionType.ADD_STORY_BLOCKS_SUCCESS:
        case ActionType.EDIT_STORY_BLOCK_SUCCESS:
        case ActionType.EDIT_STORY_BLOCK_ERROR:
            return {
                ...state,
                saving: false
            }
        default:
            return state;
    }
}

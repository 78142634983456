import React, { Suspense } from 'react';
import { Route } from 'react-router-dom'
import { Layout } from 'antd';
import SiteHeader from '../components/header';
import SettingsMenu from '../components/site/settings/SettingsMenu';
import Can from '../services/permisions/Can'

const { Content } = Layout;

/* Main Layout */
const SettingsLayout = ({ children, ...rest }) => {
  return (
    <Layout>
      <SiteHeader isSettingsHeader={true}></SiteHeader>
      <Layout>
        <Layout>
          <Content>
            <div className="settings-block tb--pb-8">
              <SettingsMenu />
              <div className="settings--section-content">
                {children}
              </div>
            </div>
          </Content>
        </Layout>
      </Layout>
    </Layout>
  )
}

/*
  Private route wrapper
 */

export const SettingsLayoutRoute = ({ component: Component, ...rest }) => {
  const permissionName = typeof rest.permissions.name === 'object' ? rest.permissions.name[rest.computedMatch.params.type] : rest.permissions.name;
  return (
    <Route {...rest} render={matchProps => (
      !rest.permissions ?
        <SettingsLayout>
          <Suspense fallback={<></>}>
            <Component {...matchProps} />
          </Suspense>
        </SettingsLayout> :
        <Can I={rest.permissions.type} a={permissionName} withRedirect={true} >
          <SettingsLayout>
            <Suspense fallback={<></>}>
              <Component {...matchProps} />
            </Suspense>
          </SettingsLayout>
        </Can>
    )} />
  )
};
import { InfoCircleFilled } from "@ant-design/icons";
import { Modal, Radio, Space, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { changeCloudProvider, getCloudProvider, getProject } from "../../actions/projects/ProjectsActions";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
const confirm = Modal.confirm;

export const CloudProviderTypes = {
    Cloudflare: 1,
    Nginx: 2
}

const CloudProvider = ({setCloudProvider, selectedCloudProvider}) => {
    const { cloudProvider } = useSelector(state => state.builder.projects.selectedProject);
    const intl = useSelector(state => state.intl);
    const dispatch = useDispatch();
    const params = useParams();
    const [clouds, setClouds] = useState({});

    const CloudTexts = {
        1: {
            title: intl.messages.cloudflare,
            infoHover: intl.messages.cloudflare_hover_text
        },
        2: {
            title: intl.messages.nginx,
            infoHover: intl.messages.nginx_hover_text
        }
    }

    useEffect(() => {
        dispatch(getCloudProvider()).then(response => {
            setClouds(response.data)
        });
    }, [])

    useEffect(() => {
        setCloudProvider(cloudProvider)
    }, [cloudProvider])

    const handleProvideChange = (option) => {
        confirm({
            title: intl.messages.cloud_provider_modal_title,
            content: <div className="tb--footer-delete-item ">
                <p className='tb--pb-0 tb--mb-0'>
                    {intl.messages.cloud_provider_modal_desc}
                </p>
            </div>,
            okText: intl.messages.approve,
            cancelText: intl.messages.cancel,
            onOk() {
                dispatch(changeCloudProvider(option)).then((response) => {
                    if (response) {
                        dispatch(getProject(params.id))
                    }
                })
                setCloudProvider(option)
            },
        });
    }
    
    return <div className="products--section">
        <div className='products--section-top'>
            <div>
                <div className='products--section-title tb--flex'>
                    <span className='products--section-icon'><i className='cloud-bicon'></i></span>
                    <h3 className='tb--f-18 tb--mb-0'>{intl.messages.cloud_provider}</h3>
                </div>
            </div>
            <div className='tb--direction-column'>
                <p className='tb--f-16'>
                    <p>{intl.messages.cloud_provider_desc}</p>
                </p>
                <div className="tb--pb-8">
                    <Radio.Group onChange={(e) => handleProvideChange(e.target.value)} value={selectedCloudProvider} className="tb--w100">
                        {Object.keys(clouds).map((cloudId) => (
                            <Space key={+cloudId} className="tb--mb-8">
                                <div className="tb--cloud-option tb--flex tb--justify-between tb--align-center tb--ph-12 tb--pv-12">
                                    <div
                                        className={`tb--cloud-option_title ${selectedCloudProvider === +cloudId ? 'tb--cloud-option_title_selected' : ''
                                            } tb--flex`}
                                    >
                                        <span className="tb--mb-0">{CloudTexts[cloudId].title}</span>
                                        <Tooltip title={CloudTexts[cloudId].infoHover}>
                                            <InfoCircleFilled className="tb--mh-8 tb--flex tb--align-center tb--f-14 tb--tooltip" />
                                        </Tooltip>
                                    </div>
                                    <div className="tb--flex tb--align-center">
                                        <Tooltip placement="top">
                                            <i className="info-circle tb--cp"></i>
                                        </Tooltip>
                                        <Radio value={+cloudId} className="tb--ml-24"></Radio>
                                    </div>
                                </div>
                            </Space>
                        ))}
                    </Radio.Group>

                </div>
            </div>
        </div>
    </div>
}

export default CloudProvider;
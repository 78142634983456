import { ActionType } from '../../../constants/ActionTypes';
export default function logosReducer(state = {}, action = {}) {
    switch (action.type) {
        case ActionType.GET_LOGOS_SUCCESS:
            return {
                ...state,
                inLoadingProcess: false,
                data: action.payload
            }
        case ActionType.GET_LOGOS_ERROR:
            return {
                ...state,
                inLoadingProcess: false
            }
        case ActionType.SET_LOGOS_ERROR:
            return {
                ...state,
                error: action.payload,
                inSavingProcess: false
            }
        case ActionType.SET_LOGOS_SUCCESS:
            return {
                ...state,
                inSavingProcess: false,
                data: {
                    ...state.data,
                    logoLink: action.payload
                }
            }
        case ActionType.GET_LOGOS_BEGIN:
            return {
                ...state,
                inLoadingProcess: true,
                inSavingProcess: false,
                data: []
            }
        case ActionType.SET_LOGOS_BEGIN:
            return {
                ...state,
                inSavingProcess: true
            }
        case ActionType.UPLOAD_LOGOS_BEGIN:
            return {
                ...state,
                [action.logoType + 'Loading']: true
            }
        case ActionType.UPLOAD_LOGOS_ERROR:
            return {
                ...state,
                [action.logoType + 'Loading']: false
            }
        case ActionType.UPLOAD_LOGOS_SUCCESS:
            return {
                ...state,
                [action.logoType + 'Loading']: false,
                data: {
                    ...state.data,
                    [action.logoType + 'Url']: {
                        ...state.data[action.logoType + 'Url'],
                        value: action.logo
                    }
                }
            }
            case ActionType.GET_LOGOS_SETTINGS_BEGIN:
                return {
                    ...state,
                    settingsLoading: true
    
                }
            case ActionType.GET_LOGOS_SETTINGS_ERROR:
                return {
                    ...state,
                    settingsLoading: false
    
                }
            
        case ActionType.GET_LOGO_SETTINGS_SUCCESS:
            return {
                ...state,
                settings: action.payload,
                settingsLoading: false

            }
        case ActionType.CHANGE_LOGO_SETTINGS_SUCCESS:
            return {
                ...state,
                settings: action.payload,
                settingsLoading: false
            }
        default:
            return state;
    }
}

import { ActionType } from '../../constants/ActionTypes';


export default function userManagementReducer(state = {}, action = {}) {
    switch (action.type) {
        case ActionType.GET_USERS_BEGIN:
            return {
                ...state,
                loading: true,
                data: []
            }
        case ActionType.GET_USER_ERROR:
        case ActionType.GET_USERS_ERROR:
        case ActionType.CREATE_USER_ERROR:
        case ActionType.EDIT_USER_ERROR:
            return {
                ...state,
                error: action.payload,
                loading: false
            }
        case ActionType.GET_USERS_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload
            }
        case ActionType.GET_USER_BEGIN:
            return {
                ...state,
                loading: true,
                currentUser: []
            }
        case ActionType.GET_USER_SUCCESS:
            return {
                ...state,
                loading: false,
                currentUser: action.payload
            }
        case ActionType.EDIT_USER_BEGIN:
        case ActionType.CREATE_USER_BEGIN:
            return {
                ...state,
                loading: true,
            }
        case ActionType.CREATE_USER_SUCCESS:
        case ActionType.EDIT_USER_SUCCESS:
            return {
                ...state,
                loading: false
            }
        default:
            return state;
    }
}

import { RoutePath } from '../../constants/SitePaths'
import { LocalStorageService } from '../LocalStorageService';
import RolesEnum from './RolesEnum'
import { Auth } from '../Auth';


const RolesHelper = {
    getRedirectPage() {
        if (Auth.isAuthenticated()) {
            if (RolesHelper.isAffilateUser()) {
                return RoutePath.NOTFOUND;
            } else {
                return RoutePath.PROJECTS;
            }
        } else {
            // loginState = false;
            return RoutePath.LOGIN;
        }
    },
    isAffilateUser() {
        return LocalStorageService.get('role') == RolesEnum.Affiliate
    }
}

export default RolesHelper;
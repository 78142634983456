
/** LocalStorageService namespace
 * @namespace
 */
export const LocalStorageService = {
    /**Store data in local storage by key
    * @param {string} key - The key.
    * @param {Object} key - The stored data.
    */
    set: (key, value, config = {}) => {
        let a;
        let val = config.ttl && config.ttl > 0 ? (a = {}, a['data'] = value, a.ttl = Date.now() + config.ttl * 1e3, a) : value
        localStorage.setItem(key, JSON.stringify(val));
        //this.setState({ hits: result.hits });
    },
    /**Clear all data from local storage
    */
    clear: () => {
        localStorage.clear();
    },
    /**Remove data by key from local storage
    * @param {string} key - The data's key in local storage.
    */
    remove: (key) => {
        localStorage.removeItem(key);
    },
    /**Get data by key from local storage
    * @param {string} key - The data's key in local storage.
    * @returns {Object} - The stored data by particular key 
    */
    get: (key) => {
        let str = localStorage.getItem(key);
        if(!str){
            return null
        }
        let item = JSON.parse(str);
        let hasTTL = typeof item === 'object' && 'data' in item;
        if(!hasTTL){
            return item
        }
        if (Date.now() > item.ttl) {
            localStorage.removeItem(key);
            return null;
        }
        return item['data'];
    }
}

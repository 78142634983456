import { ActionType } from '../../../constants/ActionTypes';

export default function mobileAppsReducer(state = {}, action = {}) {
    switch (action.type) {
        case ActionType.GET_MOBILE_APPS_BEGIN:
        case ActionType.SET_MOBILE_APPS_BEGIN:
        case ActionType.SET_MOBILE_APPS_DEVICE_TYPE_BEGIN:
            return {
                ...state,
                inProcess: true
            }
   
        case ActionType.GET_MOBILE_APPS_SUCCESS:
            return {
                ...state,
                data: action.payload,
                inProcess: false
            }
        case ActionType.SET_MOBILE_APPS_SUCCESS:
        case ActionType.GET_MOBILE_APPS_ERROR:
        case ActionType.SET_MOBILE_APPS_ERROR:
        case ActionType.SET_MOBILE_APPS_DEVICE_TYPE_SUCCESS:
        case ActionType.SET_MOBILE_APPS_DEVICE_TYPE_ERROR:
            return {
                ...state,
                inProcess: false
            }
            
        default:
            return state;
    }
}

import ActionType from '../../constants/ActionTypes';
//UI settings reducer
export default function iconSetReducer(state = {}, action = {}) {

    switch (action.type) {
        case ActionType.GET_ICON_SET_BEGIN:
        case ActionType.SET_ICON_SET_BEGIN:
            return {
                ...state,
                inProcess: true
            }
        case ActionType.GET_ICON_SET_ERROR:
        case ActionType.SET_ICON_SET_ERROR:
        case ActionType.SET_ICON_SET_SUCCESS:
            return {
                ...state,
                inProcess: false
            }
        case ActionType.GET_ICON_SET_SUCCESS:
            return {
                ...state,
                inProcess: false,
                iconSetType: action.payload
            }
        case ActionType.GET_ICONS_BEGIN:
            return {
                ...state,
                loading: true
            }
        case ActionType.GET_ICONS_ERROR:
            return {
                ...state,
                loading: false
            }
        case ActionType.GET_ICONS_SUCCESS:
            return {
                ...state,
                loading: false,
                icons: action.payload
            }
        default:
            return state;
    }
}

import ActionType from '../../constants/ActionTypes';
//UI settings reducer
export default function mobileAppReducer(state = {}, action = {}) {

    switch (action.type) {
        case ActionType.GET_MOBILE_APP_DATA_BEGIN:
        case ActionType.EDIT_MOBILE_APP_DATA_BEGIN:
            return {
                ...state,
                inProcess: true
            }
        case ActionType.GET_MOBILE_APP_DATA_ERROR:
        case ActionType.EDIT_MOBILE_APP_DATA_ERROR:
        case ActionType.EDIT_MOBILE_APP_DATA_SUCCESS:
            return {
                ...state,
                inProcess: false
            }
        case ActionType.GET_MOBILE_APP_DATA_SUCCESS:
            return {
                ...state,
                inProcess: false,
                data: action.payload
            }
        default:
            return state;
    }
}
import { ActionType } from '../../constants/ActionTypes';
//Set Device mode
export const storeDeviceMode = mode => ({
    type: ActionType.SET_DEVICE_MODE,
    payload: mode
});


export function setDeviceMode(mode) {
    return dispatch => {
        dispatch(storeDeviceMode(mode));
    }
}
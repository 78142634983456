import { ActionType } from '../../../constants/ActionTypes';

export default function customCodeReducer(state = {}, action = {}) {

    switch (action.type) {
        //Get custom codes
        case ActionType.CLEAR_CUSTOM_CODES:
            return {
                ...state,
                error: null,
                scripts: [],
                editableScript: {},
                styles: [],
                inSavingProcess: false,
                inLoadingProcess: false
            }
        case ActionType.GET_CUSTOM_CODES_BEGIN:
            return {
                ...state,
                error: null,
                inSavingProcess: true,
                inLoadingProcess: true
            }
        case ActionType.GET_CUSTOM_CODES_ERROR:
        case ActionType.GET_CUSTOM_CODE_ERROR:
            return {
                ...state,
                error: action.payload,
                inSavingProcess: false,
                inLoadingProcess: false
            }
        case ActionType.GET_CUSTOM_CODES_SUCCESS:
            return {
                ...state,
                [`${action.payload.type}s`]: action.payload.list,
                inSavingProcess: false,
                inLoadingProcess: false
            }
        //Get custom code
        case ActionType.GET_CUSTOM_CODE_BEGIN:
        case ActionType.CLEAR_CODE_DETAILS:
            return {
                ...state,
                editableScript: {}
            }
        case ActionType.GET_CUSTOM_CODE_SUCCESS:
            return {
                ...state,
                editableScript: action.payload,
                inSavingProcess: false,
                inLoadingProcess: false
            }
        //Add custom code
        case ActionType.ADD_CUSTOM_CODE_BEGIN:
        case ActionType.EDIT_CUSTOM_CODE_BEGIN:
        case ActionType.CHANGE_CUSTOM_CODE_STATE_BEGIN:
            return {
                ...state,
                inSavingProcess: true
            }
        case ActionType.ADD_CUSTOM_CODE_ERROR:
        case ActionType.ADD_CUSTOM_CODE_SUCCESS:
        case ActionType.EDIT_CUSTOM_CODE_ERROR:
        case ActionType.EDIT_CUSTOM_CODE_SUCCESS:
        case ActionType.CHANGE_CUSTOM_CODE_STATE_ERROR:
        case ActionType.CHANGE_CUSTOM_CODE_STATE_SUCCESS:
            return {
                ...state,
                inSavingProcess: false,
                inLoadingProcess: false
            }
        //Delete custom code
        case ActionType.DELETE_CUSTOM_CODE_BEGIN:
        case ActionType.DELETE_CUSTOM_CODE_SUCCESS:
        case ActionType.DELETE_CUSTOM_CODE_ERROR:
            return {
                ...state
            }
        default:
            return state;
    }
}

import ActionType from '../../constants/ActionTypes';

export default function deviceModeReducer(state = {}, action = {}) {

    switch (action.type) {
       
        case ActionType.SET_DEVICE_MODE:
            return action.payload;

        default:
            return state;
    }
}
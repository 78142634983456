import ActionType from '../../constants/ActionTypes';
import { Utils } from '../../services/Utils';

export default function controlsReducer(state = {}, action = {}) {
    let changedControls;
    let menuName;
    let menuItems;
    switch (action.type) {
        case ActionType.GET_CONTROLS_TREE_BEGIN:
            return {
                ...state,
                inProcess: true,
                MainMenu: null,
                SecondaryMenu: null,
                Rules: null,
                FooterMenu: null,
                MobileNavBar: null,
                FloatingMenu: null,
                registrationForm: null,
                loginForm: null,
                forgotPasswordForm: null,
                forgotUsernameForm: null,
                recoverPasswordForm: null,
                account: null,

            }
        case ActionType.GET_CONTROLS_TREE_AND_KEEP_OLD_BEGIN:
        case ActionType.GET_LOGIN_FORM_OPTION_BEGIN:
        case ActionType.SET_LOGIN_FORM_OPTION_BEGIN:
        case ActionType.GET_FORGOT_USERNAME_STATE_BEGIN:
        case ActionType.SWITCH_FORGOT_USERNAME_STATE_BEGIN:
        case ActionType.ADD_MENU_ITEM_BEGIN:
        case ActionType.EDIT_MENU_ITEM_BEGIN:
        case ActionType.MOVE_MENU_ITEM_BEGIN:
        case ActionType.MOVE_FORM_CONTROL_BEGIN:
        case ActionType.CHANGE_CONTROL_VISIBILITY_BEGIN:
        case ActionType.DELETE_MENU_ITEM_BEGIN:
            return {
                ...state,
                inProcess: true
            }
        case ActionType.SET_SITE_VERSION:
            return {
                ...state, 
                siteVersion: action.payload
            }
        case ActionType.GET_CONTROLS_TREE_SUCCESS:
            return {
                ...state,
                inProcess: false,
                [action.payload.section]: action.payload,
                itemMoved: false,
                languageLoading: false
            }

        case ActionType.GET_CONTROLS_TREE_ERROR:
            return {
                ...state,
                inProcess: false,
                error: action.payload,
                languageLoading: false
            }
        case ActionType.GET_LOGIN_FORM_OPTION_ERROR:
        case ActionType.SET_LOGIN_FORM_OPTION_ERROR:
        case ActionType.SET_LOGIN_FORM_OPTION_SUCCESS:
        case ActionType.GET_FORGOT_USERNAME_STATE_ERROR:
        case ActionType.SWITCH_FORGOT_USERNAME_STATE_ERROR:
        case ActionType.SWITCH_FORGOT_USERNAME_STATE_SUCCESS:
        case ActionType.ADD_MENU_ITEM_ERROR:
        case ActionType.CHANGE_CONTROL_VISIBILITY_ERROR:
        case ActionType.DELETE_MENU_ITEM_ERROR:
            return {
                ...state,
                inProcess: false,
            }
        case ActionType.GET_LOGIN_FORM_OPTION_SUCCESS:
            return {
                ...state,
                inProcess: false,
                loginOptions: action.payload
            }
        case ActionType.GET_FORGOT_USERNAME_STATE_SUCCESS:
            return {
                ...state,
                inProcess: false,
                forgotUsernameOptions: action.payload
            }
        case ActionType.CHANGE_LANGUAGE_WAITING_STATE:
            return {
                ...state,
                languageLoading: true
            }
        case ActionType.GET_FOOTER_CONTROLS_TREE_SUCCESS:
            return {
                ...state,
                inProcess: false,
                footerControls: action.payload,
                itemMoved: false
            }
        case ActionType.EDIT_FORM_ITEM_ERROR:
            return {
                ...state,
                hideItems: true
            }
        case ActionType.EDIT_MENU_ITEM_ERROR:
        case ActionType.MOVE_MENU_ITEM_ERROR:
        case ActionType.MOVE_FORM_CONTROL_ERROR:
            return {
                ...state,
                inProcess: false,
                hideItems: true
            }
        case ActionType.ADD_MENU_ITEM_SUCCESS:
        case ActionType.EDIT_FORM_ITEM_SUCCESS:
        case ActionType.EDIT_MENU_ITEM_SUCCESS:
            return {
                ...state,
                error: null,
                inProcess: false,
                hideItems: true
            }
        case ActionType.MOVE_MENU_ITEM_SUCCESS_SHOW:
            return {
                ...state,
                hideItems: false
            }
        case ActionType.MOVE_FORM_CONTROL_SUCCESS:
        case ActionType.MOVE_MENU_ITEM_SUCCESS_HIDE:
            menuName = action.payload.menuName;
            menuItems = state[menuName].nestedControls;
            let menuItemIndex;
            let movedControls;
            let movedItem = null;
            menuItemIndex = menuItems.findIndex(menuItem => menuItem.id === action.payload.controlId);
            if (menuItemIndex >= 0) {
                if (state[menuName].id === action.payload.parentControlId) {
                    movedControls = Utils.move(menuItemIndex, action.payload.order - 1, ...menuItems);
                } else {
                    movedItem = menuItems[menuItemIndex];
                    movedControls = menuItems.filter(menuItem => menuItem.id !== action.payload.controlId)
                }
            } else {
                movedControls = menuItems.map(menu => {
                    const innerItemIndex = menu.nestedControls.findIndex(menuItem => menuItem.id === action.payload.controlId);
                    if (innerItemIndex >= 0) {
                        if (action.payload.parentControlId === menu.id) {
                            return { ...menu, nestedControls: Utils.move(innerItemIndex, action.payload.order - 1, ...menu.nestedControls) }
                        } else {
                            movedItem = menu.nestedControls[innerItemIndex];
                            return { ...menu, nestedControls: menu.nestedControls.filter(menuItem => menuItem.id !== action.payload.controlId) }
                        }
                    }
                    return menu;
                });
            }

            if (movedItem) {
                if (state[menuName].id === action.payload.parentControlId) {
                    movedControls = Utils.insert(movedControls, action.payload.order - 1, movedItem);
                } else {
                    movedControls = movedControls.map(menu => {
                        if (action.payload.parentControlId === menu.id) {
                            return { ...menu, nestedControls: Utils.insert(menu.nestedControls, action.payload.order - 1, movedItem) }
                        }
                        return menu;
                    });
                }
            }
            return {
                ...state,
                [menuName]: {
                    ...state[menuName],
                    nestedControls: movedControls
                },
                hideItems: true,
                inProcess: false,
                itemMoved: true
            }
        case ActionType.CHANGE_REQUIRED_FORM_ITEM_SUCCESS:
            menuName = action.payload.menuName;
            changedControls = state[menuName].nestedControls.map(menu => {
                const nestedControls = menu.id === action.payload.id ? [] : menu.nestedControls.map(subMenu => {
                    return subMenu.id === action.payload.id ? {
                        ...subMenu,
                        properties: { ...subMenu.properties, required: { value: action.payload.properties.required.value, isTranslatable: false } }
                    } : subMenu;
                });
                return menu.id === action.payload.id ? { ...menu, properties: { ...menu.properties, required: { value: action.payload.properties.required.value, isTranslatable: false } } } : { ...menu, nestedControls };
            });
            return {
                ...state,
                [menuName]: {
                    ...state[menuName],
                    nestedControls: changedControls
                },
                inProcess: false
            }
        case ActionType.CHANGE_CONTROL_VISIBILITY_SUCCESS:
            menuName = action.payload.menuName;
            changedControls = state[menuName].nestedControls.map(menu => {
                const nestedControls = menu.id === action.payload.controlId ? [] : menu.nestedControls.map(subMenu => {
                    return subMenu.id === action.payload.controlId ? { ...subMenu, isVisible: !subMenu.isVisible } : subMenu;
                });
                return menu.id === action.payload.controlId ? { ...menu, isVisible: !menu.isVisible } : { ...menu, nestedControls };
            });
            return {
                ...state,
                [menuName]: {
                    ...state[menuName],
                    nestedControls: changedControls
                },
                inProcess: false
            }
        case ActionType.DELETE_MENU_ITEM_SUCCESS:
            menuName = action.payload.menuName;
            changedControls = state[menuName].nestedControls.filter(menuItem => menuItem.id !== action.payload.controlId).map(menu => {
                const nestedControls = menu.nestedControls.filter(subMenu => subMenu.id !== action.payload.controlId);
                return { ...menu, nestedControls };
            });

            return {
                ...state,
                [menuName]: {
                    ...state[menuName],
                    nestedControls: changedControls
                },
                inProcess: false,
            }
        case ActionType.GET_MENU_SETTINGS_BEGIN:
            return {
                ...state,
                settingsLoading: true
            }
        case ActionType.GET_MENU_SETTINGS_ERROR:
            return {
                ...state,
                settingsLoading: false
            }
        case ActionType.GET_MENU_SETTINGS_SUCCESS:
            return {
                ...state,
                settings: action.payload,
                settingsLoading: false
            }

        case ActionType.GET_REGISTRAION_VERIFICATION_BEGIN:
            return {
                ...state,
                isLoading: true
            }
        case ActionType.GET_REGISTRAION_VERIFICATION_SUCCESS:
            return {
                ...state,
                verificationType: action.payload,
                isLoading: false
            }
        case ActionType.GET_REGISTRAION_VERIFICATION_ERROR:
            return {
                ...state,
                isLoading: false
            }
        case ActionType.SWITCH_REGISTRATION_VERIFICATION_BEGIN:
            return {
                ...state,
                isSaving: true
            }
        case ActionType.SWITCH_REGISTRATION_VERIFICATION_SUCCESS:
            return {
                ...state,
                isSaving: false
            }
        case ActionType.SWITCH_REGISTRATION_VERIFICATION_ERROR:
            return {
                ...state,
                isSaving: false
            }
        case ActionType.RULES_BUTTONS_VISIBILITY_BEGIN:
            return {
                ...state,
                loading: true
            }
        case ActionType.RULES_BUTTONS_VISIBILITY_ERROR:
            return {
                ...state,
                loading: false
            }
        case ActionType.RULES_BUTTONS_VISIBILITY_SUCCESS:
            return {
                ...state,
                loading: false,
                rulesButtonsVisibility: action.payload
            }    
        default:
            return state;
    }
}
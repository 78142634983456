import { ActionType } from '../constants/ActionTypes'
import { Auth } from '../services/Auth'


export default function userReducer(state = {}, action = {}) {

    switch (action.type) {
        //Login
        case ActionType.LOGIN_USER_BEGIN:
        case ActionType.GET_USER_DATA_BEGIN:
        case ActionType.REGISTER_USER_BEGIN:
        case ActionType.VERIFY_USER_BEGIN:
            return {
                ...state,
                isLoading: true,
                isLoggedIn: false,
            }
        case ActionType.RESET_LOADING:
        case ActionType.SET_PASSWORD_SUCCESS:
        case ActionType.CHECK_HASH_SUCCESS:
            return {
                ...state,
                isLoading: false
            }
        case ActionType.LOGIN_USER_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isLoggedIn: true,
                error: null,
                // email: action.payload.user.userName,
                // firstName: action.payload.user.firstName,
                // lastName: action.payload.user.lastName,
                // mobileNumber: action.payload.user.mobileNumber,
                // companyName: action.payload.user.companyName,
                // SuperAdmin: action.payload.user.SuperAdmin
            }
        case ActionType.LOGIN_USER_FAILURE:
            return {
                ...state,
                isLoading: false,
                isLoggedIn: false,
                email: action.payload.user.username,
                error: action.payload.error
            }
        case ActionType.GET_USER_DATA_SUCCESS:
            const user = {
                ...state,
                email: action.payload.userName,
                firstName: action.payload.firstName,
                lastName: action.payload.lastName,
                mobileNumber: action.payload.mobileNumber,
                permissions: action.payload.permissions,
                superAdmin: action.payload.superAdmin,
                company: action.payload.company,

                isLoading: false,
                isLoggedIn: true,
                error: null,

            }
            return user;
        case ActionType.GET_USER_DATA_ERROR:
            return {
                ...state,
                email: {
                    ...state.email,
                    isLoading: false,
                    error: action.payload && action.payload.error
                }
            }
        case ActionType.REGISTER_USER_SUCCESS:
            return {
                ...state,
                isLoading: false,
                email: action.payload.user.userName,
            }
        case ActionType.REGISTER_USER_FAILURE:
        case ActionType.VERIFY_USER_FAILURE:
            return {
                ...state,
                isLoading: false,
                isLoggedIn: false,
                error: action.payload.error
            }
        //Set password   
        case ActionType.SET_PASSWORD_BEGIN:
        case ActionType.CHECK_HASH_BEGIN:
            return {
                ...state,
                isLoading: true
            }
        case ActionType.SET_PASSWORD_ERROR:
        case ActionType.CHECK_HASH_ERROR:
            return {
                ...state,
                isLoading: false,
                error: action.payload
            }
        case ActionType.VERIFY_USER_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isLoggedIn: true,
                email: action.payload.user.userName,
            }
        case ActionType.USER_LOGOUT:
            Auth.invalidateSession();
            return { ...state, isLoggedIn: false }

        // Profile Change Fullname
        case ActionType.CHANGE_FULLNAME_BEGIN:
        case ActionType.CHANGE_FULLNAME_ERROR:
            return {
                ...state,
            }
        case ActionType.CHANGE_FULLNAME_SUCCESS:
            return {
                ...state,
                firstName: action.payload.firstName,
                lastName: action.payload.lastName,
            }
        // Profile Change Email

        // case ActionType.VERIFY_CHANGED_EMAIL_BEGIN:
        //     return {
        //         ...state,
        //     }
        case ActionType.VERIFY_CHANGED_EMAIL_SUCCESS:
            return {
                ...state,
                email: action.payload,
            }

        // case ActionType.VERIFY_CHANGED_EMAIL_FAILURE:
        //     return {
        //         ...state,
        //     }


        /* Profile Mobile Email */

        case ActionType.VERIFY_CHANGED_MOBILE_SUCCESS:
            return {
                ...state,
                mobileNumber: action.payload,
            }

        case ActionType.GET_LOCATION_SUCCESS:
            return {
                ...state,
                location: action.payload
            }
        case ActionType.UPDATE_ABILITIES:
            return {
                ...state,
                abilities: action.payload
            }
        case ActionType.GENERATE_TFA_CODE_BEGIN:
            return {
                ...state,
                tfa: {
                    isTFALoading: false,
                }
            }
        case ActionType.GENERATE_TFA_CODE_FAILURE:
            return {
                ...state,
                tfa: {
                    isTFALoading: false,
                }
            }
        case ActionType.GENERATE_TFA_CODE_SUCCESS:
            return {
                ...state,
                tfa: {
                    isTFALoading: false,
                    ...action.payload
                }
            }
        default:
            return state;
    }

}


import ActionType from '../../constants/ActionTypes';
//UI settings reducer
export default function languageBarReducer(state = {}, action = {}) {

    switch (action.type) {
        case ActionType.GET_LANGUAGE_CONTROLS_BEGIN:
        case ActionType.EDIT_LANGUAGE_CONTROLS_BEGIN:
            return {
                ...state,
                inProcess: true
            }
        case ActionType.GET_LANGUAGE_CONTROLS_ERROR:
        case ActionType.EDIT_LANGUAGE_CONTROLS_ERROR:
        case ActionType.EDIT_LANGUAGE_CONTROLS_SUCCESS:
            return {
                ...state,
                inProcess: false
            }
        case ActionType.GET_LANGUAGE_CONTROLS_SUCCESS:
            return {
                ...state,
                inProcess: false,
                languageBar: action.payload
            }
        default:
            return state;
    }
}

import ActionType from '../../constants/ActionTypes';

export default function templatesReducer(state = {}, action = {}) {
    switch (action.type) {
        case ActionType.GET_TEMPLATE_COLOR_SETS_BEGIN:
            return {
                ...state,
                inProcess: true
            }
        case ActionType.GET_TEMPLATE_COLOR_SETS_ERROR:
            return {
                ...state,
                error: action.payload,
                inProcess: false
            }
        case ActionType.GET_TEMPLATE_COLOR_SETS_SUCCESS:
            return {
                ...state,
                colorset: action.payload,
                inProcess: false
            }
        default:
            return state;
    }
}
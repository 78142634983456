import template1 from './templates/template1'
import template2 from './templates/template2'
import template3 from './templates/template3'
import template4 from './templates/template4'
import template5 from './templates/template5'
import template7 from './templates/template7'
export const templateControls = {
    //TEMPLATE 1 CONTROLS
    1: template1,
    //TEMPLATE 2 CONTROLS
    2: template2,
    //TEMPLATE 3 CONTROLS
    3: template3,
    //TEMPLATE 4 CONTROLS
    4: template4,
    //TEMPLATE 5 CONTROLS
    5: template5,
    //TEMPLATE 7 CONTROLS
    7: template7,
}

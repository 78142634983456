import { ActionType } from '../../constants/ActionTypes';


export default function promotionsReducer(state = {}, action = {}) {
    switch (action.type) {
        case ActionType.GET_PROMOTIONS_BEGIN:
            return {
                ...state,
                loading: true,
                promotionsData: [],
                error: null,
                saving: false
            }
        case ActionType.GET_PROMOTIONS_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        case ActionType.GET_PROMOTIONS_SUCCESS:

            return {
                ...state,
                loading: false,
                promotionsData: action.payload.data

            }
        case ActionType.ADD_PROMOTION_BEGIN:
        case ActionType.EDIT_PROMOTION_BEGIN:
            return {
                ...state,
                saving: true
            }
        case ActionType.ADD_PROMOTION_ERROR:
        case ActionType.ADD_PROMOTION_SUCCESS:
        case ActionType.EDIT_PROMOTION_ERROR:
        case ActionType.EDIT_PROMOTION_SUCCESS:
            return {
                ...state,
                saving: false
            }
        case ActionType.DELETE_PROMOTION_BEGIN:
        case ActionType.CHANGE_PROMOTION_VISIBILITY_BEGIN:
        case ActionType.MOVE_PROMOTION_BEGIN:
            return {
                ...state,
                loading: true
            }
        case ActionType.HIGHLIGHT_PROMOTION_BEGIN:
            return {
                ...state,
                highlighteLoading: true
            }
        case ActionType.DELETE_PROMOTION_ERROR:
        case ActionType.CHANGE_PROMOTION_VISIBILITY_ERROR:
        case ActionType.MOVE_PROMOTION_ERROR:
        case ActionType.MOVE_PROMOTION_SUCCESS:
        
            return {
                ...state,
                loading: false
            }
            case ActionType.HIGHLIGHT_PROMOTION_ERROR:
            return {
                ...state,
                highlighteLoading: false
            }
        case ActionType.DELETE_PROMOTION_SUCCESS:
            const data = state.promotionsData.filter(promotion => promotion.id !== action.payload);
            return {
                ...state,
                promotionsData: data,
                loading: false
            }
        case ActionType.CHANGE_PROMOTION_VISIBILITY_SUCCESS:
            const changedPromotions = state.promotionsData.map((promotion) => {
                return action.payload.id === promotion.id ? { ...promotion, isVisible: action.payload.isVisible } : promotion
            })
          
            return {
                ...state,
                promotionsData: changedPromotions,
                loading: false
            }
        case ActionType.HIGHLIGHT_PROMOTION_SUCCESS:
            const highlightedPromotions = state.promotionsData.map((promotion) => {
                
                return action.payload.promotionId === promotion.id ? { ...promotion, isHighlighted: action.payload.isHighlighted} : promotion
            })
           
            return {
                ...state,
                promotionsData: highlightedPromotions,
                highlighteLoading: false
            }
        case ActionType.GET_PROMOTIONS_CATEGORIES_BEGIN:
            return {
                ...state,
                categories: [],
                categoryLoading: true
            }
        case ActionType.GET_PROMOTIONS_CATEGORIES_ERROR:
        case ActionType.ADD_PROMOTION_CATEGORY_ERROR:
        case ActionType.DELETE_PROMOTION_CATEGORY_ERROR:
        case ActionType.EDIT_PROMOTION_CATEGORY_ЕRROR:
        case ActionType.MOVE_PROMOTION_CATEGORY_ERROR:
        case ActionType.ADD_PROMOTION_CATEGORY_SUCCESS:
        case ActionType.MOVE_PROMOTION_CATEGORY_SUCCESS:
        case ActionType.GET_CATEGORIES_ICONS_VISIBILITY_ERROR:
        case ActionType.CHANGE_CATEGORIES_ICONS_VISIBILITY_ERROR:
            return {
                ...state,
                categoryLoading: false
            }
        case ActionType.GET_PROMOTIONS_CATEGORIES_SUCCESS:
            return {
                ...state,
                categories: action.payload,
                categoryLoading: false
            }
        case ActionType.GET_CATEGORY_ICONS_BEGIN:
            return {
                ...state,
                categoryIcons: [],
                categoryIconsLoading: true
            }
        case ActionType.GET_CATEGORY_ICONS_ERROR:
            return {
                ...state,
                categoryIconsLoading: false
            }
        case ActionType.GET_CATEGORY_ICONS_SUCCESS:
            return {
                ...state,
                categoryIcons: action.payload,
                categoryIconsLoading: false
            }
        case ActionType.EDIT_PROMOTION_CATEGORY_SUCCESS:
            const changedCategories = state.categories.map((category) => {
                return action.payload.id === category.id ? { ...category, isVisible: action.payload.isVisible } : category
            })
            return {
                ...state,
                categories: changedCategories,
                categoryLoading: false
            }
        case ActionType.DELETE_PROMOTION_CATEGORY_SUCCESS:
            const filteredCategories = state.categories.filter((category) => {
                return action.payload !== category.id;
            })
            return {
                ...state,
                categories: filteredCategories,
                categoryLoading: false
            }
        case ActionType.ADD_PROMOTION_CATEGORY_BEGIN:
        case ActionType.DELETE_PROMOTION_CATEGORY_BEGIN:
        case ActionType.EDIT_PROMOTION_CATEGORY_BEGIN:
        case ActionType.MOVE_PROMOTION_CATEGORY_BEGIN:
        case ActionType.GET_CATEGORIES_ICONS_VISIBILITY_BEGIN:
        case ActionType.CHANGE_CATEGORIES_ICONS_VISIBILITY_BEGIN:
            return {
                ...state,
                categoryLoading: true
            }
        case ActionType.GET_CATEGORIES_ICONS_VISIBILITY_SUCCESS:
        case ActionType.CHANGE_CATEGORIES_ICONS_VISIBILITY_SUCCESS:
            return {
                ...state,
                categoriesIconVisibility: action.payload,
                categoryLoading: false
            }
        case ActionType.GET_TARGET_GROUPS_SUCCESS:
            return {
                ...state,
                targetGroups: action.payload,
                targetGroupsLoading: false
            }
        default:
            return state;
    }
}

import { ActionType } from '../../constants/ActionTypes';


export default function widgetReducer(state = {}, action = {}) {
    switch (action.type) {
        case ActionType.GET_WIDGETS_BEGIN:
            return {
                ...state,
                isLoading: true,
            }
        case ActionType.GET_WIDGETS_ERROR:
            return {
                ...state,
                isLoading: false,
            }
        case ActionType.GET_WIDGETS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                widgetsObj: action.payload
            }
        default:
            return state;
    }
}

import { ActionType } from '../../../constants/ActionTypes';
export default function addOnsReducer(state = {}, action = {}) {
    switch (action.type) {
        case ActionType.GET_ADD_ONS_SUCCESS:
            return {
                ...state,
                data: action.payload,
                inProcess: false
            }
        case ActionType.GET_ADD_ONS_BEGIN:
        case ActionType.SET_ADD_ON_BEGIN:
            return {
                ...state,
                inProcess: true
            }
        case ActionType.SET_ADD_ON_ERROR:
        case ActionType.SET_ADD_ON_SUCCESS:
        case ActionType.GET_ADD_ONS_ERROR:
            return {
                ...state,
                inProcess: false
            }
        default:
            return state;
    }
}

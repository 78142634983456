import { ActionType } from '../../../constants/ActionTypes';

export default function productsReducer(state = {}, action = {}) {
    switch (action.type) {
        case ActionType.REMOVE_SPORT_BEGIN:
        case ActionType.REMOVE_CASINO_BEGIN:
        case ActionType.CHANGE_GAME_STATE_BEGIN:
            return {
                ...state,
                isSaving: true
            }
        case ActionType.REMOVE_SPORT_ERROR:
        case ActionType.ADD_SPORT_ERROR:
        case ActionType.ADD_CASINO_ERROR:
        case ActionType.REMOVE_CASINO_ERROR:
        case ActionType.CHANGE_GAME_STATE_ERROR:
        case ActionType.CHANGE_GAME_STATE_SUCCESS:
            return {
                ...state,
                isSaving: false
            }
        case ActionType.REMOVE_SPORT_SUCCESS:
        case ActionType.ADD_SPORT_SUCCESS:
            return {
                ...state,
                isSaving: false,
                data: {
                    ...state.data,
                    providers: {
                        ...state.data.providers,
                        3: {
                            ...state.data.providers[3],
                            state: action.payload
                        }
                    }
                }
            }
        case ActionType.ADD_SPORT_BEGIN:
        case ActionType.ADD_CASINO_BEGIN:
            return {
                ...state,
                isSaving: true,
                sportTypeLoading:false
            }
        case ActionType.CHANGE_SPORT_TYPE_BEGIN:
            return {
                ...state,
                sportTypeLoading: true,
            }
        case ActionType.CHANGE_SPORT_TYPE_ERROR:
        case ActionType.CHANGE_SPORT_TYPE_SUCCESS:
            return {
                ...state,
                sportTypeLoading: false,
            }
        case ActionType.ADD_CASINO_SUCCESS:
        case ActionType.REMOVE_CASINO_SUCCESS:
            return {
                ...state,
                isSaving: false,
                data: {
                    ...state.data,
                    42: {
                        ...state.data[42],
                        state: action.payload
                    }
                }
            }
        case ActionType.GET_SPORT_PRODUCTS_BEGIN:
            return {
                ...state,
                error: null,
                isSaving: false,
                isLoading: true,
                sportTypeLoading: true,
                data: {
                    ...state.data
                }
            }
        case ActionType.GET_SPORT_PRODUCTS_ERROR:
            return {
                ...state,
                error: action.payload,
                isLoading: false,
                sportTypeLoading: false,
                data: {
                    ...state.data
                }
            }
        case ActionType.GET_SPORT_PRODUCTS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                sportTypeLoading: false,
                data: action.payload
            }
        case ActionType.GET_GAMES_BEGIN:
            return {
                ...state,
                isLoading: true,
                casino: {}
            }
        case ActionType.GET_GAMES_ERROR:
            return {
                ...state,
                isLoading: false
            }
        case ActionType.GET_GAMES_SUCCESS:
            return {
                ...state,
                isLoading: false,
                casino: action.payload
            }

        case ActionType.GET_PROVIDERS_BEGIN:
            return {
                ...state,
                isLoading: true,
                providers: {}
            }
        case ActionType.GET_PROVIDERS_ERROR:
            return {
                ...state,
                isLoading: false,
            }
        case ActionType.GET_PROVIDERS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                providers: action.payload
            }
        case ActionType.GET_CATEGORIES_BEGIN:
            return {
                ...state,
                isLoading: true,
                categories: []
            }
        case ActionType.GET_CATEGORIES_ERROR:
            return {
                ...state,
                isLoading: false,
                categories: []
            }
        case ActionType.GET_CATEGORIES_SUCCESS:
            return {
                ...state,
                isLoading: false,
                categories: action.payload
            }
        default:
            return state;
    }
}

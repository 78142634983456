import {ActionType} from "../../constants/ActionTypes";

export default function templateReducer(state = {}, action = {}) {
    switch (action.type) {

        case ActionType.GET_TEMPLATES_BEGIN:
            return {
                ...state,
                inProgress: true,

            }
        case ActionType.GET_TEMPLATES_SUCCESS:
            return {
                ...state,
                inProgress:false,
                list: action.payload
            }
        case ActionType.GET_TEMPLATES_FAILURE:
            return {
                ...state,
                inProgress:false,
                error: action.payload
            }
        default:
            return state;
    }
}

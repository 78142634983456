import React from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { Layout, Select,Button } from 'antd';
import { injectIntl } from 'react-intl'

import { setUISettings } from '../../../actions/UICustomizationActions'
import { setDeviceMode } from '../../../actions/ui/DeviceModeActions'
import { getProjects, resetSelectedProject } from '../../../actions/projects/ProjectsActions'

import RolesHelper from '../../../services/permisions/RolesHelper';
import AccountMenu from '../../header/AccountMenu';

import { DeviceModeEnum } from '../../../constants/DeviceModeEnum'
import { RoutePath } from '../../../constants/SitePaths';
import config from '../../../constants/Configs';
import ProjectStateEnum from '../../../constants/ProjectStateEnum'
const { Header } = Layout;
const { Option } = Select;

class SiteHeader extends React.Component {
    componentDidMount() {
        !config.NEW_PLAT && this.props.dispatch(getProjects());
    }

    handleChangeProject = (project) => {
        this.props.history.push(`${RoutePath.CUSTOMIZE}/${project.key}?cid=${project.id}&p=${project.key}`);
        window.location.reload(false);
    }

    componentWillUnmount() {
        this.props.dispatch(resetSelectedProject())
    }

    render() {
        const { selectedProject } = this.props;
        const { allProjects } = this.props.builder.projects;
        const { projects = [] } = allProjects;
        const partnersOptions = (
            projects && projects.filter(project => project.state !== ProjectStateEnum.Blocked)
            .sort( (a, b) => { return (a.name.toLowerCase() < b.name.toLowerCase()) ? -1 : (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : 0) })
            .map(project => (
                <Option className='customization--partner' value={project.name} id={project.parrentPartnerId} key={project.id} >
                    {project.name}
                </Option>
            ))
        );
        return (
            <Header className="header tb--flex tb--align-center tb--justify-between">
                {RolesHelper.isAffilateUser() ?
                    <div></div>
                    : (!config.NEW_PLAT ?  <Link className="logo" to={"/projects"}></Link> : <div className="logo" ></div>)
                }

                <div className='customization--device-picker'>
                    <a className='customization--device' onClick={this.props.changeDeviceMode.bind(this, DeviceModeEnum.Desktop)}>
                        <i className={this.props.deviceMode === DeviceModeEnum.Desktop ? "desktop-bicon active-mode" : "desktop-bicon"}></i>
                    </a>
                    <a className='customization--device' onClick={this.props.changeDeviceMode.bind(this, DeviceModeEnum.Mobile)}>
                        <i className={this.props.deviceMode === DeviceModeEnum.Mobile ? "mobile-bicon active-mode" : "mobile-bicon"}></i>
                    </a>
                </div>

                <div className='tb--flex tb--align-center'>
                {!config.NEW_PLAT && <Select showSearch key={selectedProject.name} defaultValue={selectedProject.name} className='customization--partners'
                        onChange={(value, selectedProject) => this.handleChangeProject(selectedProject)}>
                        {partnersOptions}
                    </Select>}

                    <div className='customization--preview tb--flex tb--align-center'>
                        <a {...(selectedProject && selectedProject.domain ? {} : { className: "tb--hidden-block" })} target="_blank" href={`//${selectedProject && selectedProject.domain}?prev=${new Date().getTime()}`}>
                            <span className='eye-bicon-span'><i className="eye-bicon"></i></span>
                            {this.props.intl.messages.preview}
                        </a>
                    </div>

                    {!config.NEW_PLAT && <AccountMenu showName={false} />}
                </div>
            </Header>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return ({
        save: (props) => {
            dispatch(setUISettings(props.configs.currentSettings));
        },

        changeDeviceMode: (mode) => {
            dispatch(setDeviceMode(mode));
        },
        dispatch
    });
}

const mapStateToProps = state => {
    return {
        deviceMode: state.builder.ui.deviceMode,
        configs: state.builder.ui.settings.configs,
        selectedProject: state.builder.projects.selectedProject,
        builder: state.builder,
    }
}

export default injectIntl(withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(SiteHeader)));




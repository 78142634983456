import { ActionType } from '../../../constants/ActionTypes';

export default function thirdPartyIntegrationsReducer(state = {}, action = {}) {
    switch (action.type) {
        case ActionType.GET_EXTERNAL_SERVICES_BEGIN:
            return {
                ...state,
                error: null,
                inSavingProcess: false,
                inLoadingProcess: true
            }
        case ActionType.GET_EXTERNAL_SERVICES_ERROR:
            return {
                ...state,
                error: action.payload,
                inLoadingProcess: false
            }
        case ActionType.GET_EXTERNAL_SERVICES_SUCCESS:
            return {
                ...state,
                data: action.payload,
                inLoadingProcess: false
            }
        case ActionType.GET_STANDART_EXTERNAL_SERVICE_BEGIN:
        case ActionType.GET_CUSTOM_EXTERNAL_SERVICE_BEGIN:
        case ActionType.CLEAR_SERVICE_DETAILS:
            return {
                ...state,
                serviceDetails: {}
            }
        case ActionType.GET_STANDART_EXTERNAL_SERVICE_ERROR:
        case ActionType.GET_CUSTOM_EXTERNAL_SERVICE_ERROR:
            return {
                ...state,
                error: action.payload,
            }
        case ActionType.GET_STANDART_EXTERNAL_SERVICE_SUCCESS:
        case ActionType.GET_CUSTOM_EXTERNAL_SERVICE_SUCCESS:
            return {
                ...state,
                serviceDetails: action.payload,
            }
        case ActionType.ADD_CUSTOM_EXTERNAL_SERVICE_BEGIN:
        case ActionType.ADD_STANDART_EXTERNAL_SERVICE_BEGIN:
        case ActionType.EDIT_CUSTOM_EXTERNAL_SERVICE_BEGIN:
        case ActionType.EDIT_STANDART_EXTERNAL_SERVICE_BEGIN:
        case ActionType.EDIT_EXTERNAL_SERVICE_STATE_BEGIN:
            return {
                ...state,
                inSavingProcess: true
            }
        case ActionType.ADD_CUSTOM_EXTERNAL_SERVICE_ERROR:
        case ActionType.ADD_CUSTOM_EXTERNAL_SERVICE_SUCCESS:
        case ActionType.ADD_STANDART_EXTERNAL_SERVICE_ERROR:
        case ActionType.ADD_STANDART_EXTERNAL_SERVICE_SUCCESS:
        case ActionType.EDIT_CUSTOM_EXTERNAL_SERVICE_ERROR:
        case ActionType.EDIT_CUSTOM_EXTERNAL_SERVICE_SUCCESS:
        case ActionType.EDIT_STANDART_EXTERNAL_SERVICE_ERROR:
        case ActionType.EDIT_STANDART_EXTERNAL_SERVICE_SUCCESS:
        case ActionType.EDIT_EXTERNAL_SERVICE_STATE_ERROR:
            return {
                ...state,
                inSavingProcess: false
            }
        case ActionType.EDIT_EXTERNAL_SERVICE_STATE_SUCCESS:
            let externalServices = {};
            action.payload.name ?
                externalServices = state.data.map(service => {
                    return service.name === action.payload.name ? { ...service, active: !service.active } : service
                }) :
                externalServices = state.data.map(service => {
                    return service.id === action.payload.serviceId ? { ...service, active: !service.active } : service
                })
            return {
                ...state,
                data: externalServices,
                inSavingProcess: false
            }
        case ActionType.DELETE_EXTERNAL_SERVICE_BEGIN:
        case ActionType.DELETE_EXTERNAL_SERVICE_ERROR:
            return {
                ...state
            }
        case ActionType.DELETE_EXTERNAL_SERVICE_SUCCESS:
            action.payload.name ?
                externalServices = state.data.filter(service =>
                    service.name !== action.payload.name) :
                externalServices = state.data.filter(service =>
                    service.id !== action.payload.serviceId)
            return {
                ...state,
                data: externalServices
            }
        default:
            return state;
    }
}

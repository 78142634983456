import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import { injectIntl } from 'react-intl'
import { Layout, Select } from 'antd';
import { getProject, getProjects } from '../../actions/projects/ProjectsActions'

import { Auth } from '../../services/Auth';
import RolesHelper from '../../services/permisions/RolesHelper';
import { RoutePath } from '../../constants/SitePaths';
import AccountMenu from './AccountMenu';
import ProjectStateEnum from '../../constants/ProjectStateEnum'
import { useParams,useHistory } from 'react-router-dom';

const { Header } = Layout;
const { Option } = Select;

function SiteHeader({isSettingsHeader, intl}) {

    const user = useSelector(state => state.user);
    const selectedProject = useSelector(state => state.builder.projects.selectedProject);
    const builder = useSelector(state => state.builder);
    const params = useParams();
    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        const projectId = params.id;
        isSettingsHeader && dispatch(getProjects()).then(() => {
            projectId && dispatch(getProject(projectId));
        });

    }, [])

    const handleChangeProject = (project) => {
        history.push(`${RoutePath.SETTINGS_OVERVIEW}/${project.key}?cid=${project.id}&p=${project.key}`);
        window.location.reload(false);
    }

    const { allProjects } = builder.projects;
    const { projects = [] } = allProjects;

    const partnersOptions = (
        projects && projects.filter(project => project.state !== ProjectStateEnum.Blocked)
            .sort((a, b) => { return (a.name.toLowerCase() < b.name.toLowerCase()) ? -1 : (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : 0) })
            .map(project => (
                <Option className='settings--partner' value={project.name} id={project.parrentPartnerId} key={project.id} >
                    {project.name}
                </Option>
            ))
    );

    if (!user.isLoggedIn && !Auth.isAuthenticated()) {
        return <Redirect to='/login' />;
    }

    return (
        <Header className="header tb--flex tb--align-center tb--justify-between">
            <div>
                {RolesHelper.isAffilateUser() ?
                    <div></div>
                    : <Link to={"/projects"}>
                        <div className="logo" />
                    </Link>
                }
            </div>

            <div className='tb--flex tb--align-center'>
                {
                    isSettingsHeader && (
                        <Select showSearch key={selectedProject.name} defaultValue={selectedProject.name} className='customization--partners'
                            onChange={(value, selectedProject) => handleChangeProject(selectedProject)}>
                            {partnersOptions}
                        </Select>
                    )
                }
                {isSettingsHeader && <div className='customization--preview tb--flex tb--align-center'>
                    <a {...(selectedProject && selectedProject.domain ? {} : { className: "tb--hidden-block" })} target="_blank" href={`//${selectedProject && selectedProject.domain}?prev=${new Date().getTime()}`}>
                        <span className='eye-bicon-span'><i className="eye-bicon"></i></span>
                        {intl.messages.preview}
                    </a>
                </div>}
                <AccountMenu showName={true} isSettingsHeader={isSettingsHeader} />
            </div>
        </Header>
    );
}


export default injectIntl(SiteHeader);




import ActionType from '../../constants/ActionTypes';
//UI settings reducer
export default function accessButtonsReducer(state = {}, action = {}) {

    switch (action.type) {
        case ActionType.GET_ACCESS_BUTTONS_BEGIN:
        case ActionType.EDIT_ACCESS_BUTTONS_BEGIN:
            return {
                ...state,
                inProcess: true
            }
        case ActionType.GET_ACCESS_BUTTONS_ERROR:
        case ActionType.EDIT_ACCESS_BUTTONS_ERROR:
        case ActionType.EDIT_ACCESS_BUTTONS_SUCCESS:
            return {
                ...state,
                inProcess: false
            }
        case ActionType.GET_ACCESS_BUTTONS_SUCCESS:
            return {
                ...state,
                inProcess: false,
                data: action.payload
            }
        default:
            return state;
    }
}
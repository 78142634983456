import { ActionType } from '../../../constants/ActionTypes';
export default function overviewReducer(state = {}, action = {}) {
    switch (action.type) {
        //Get Projects Settings Info
        case ActionType.GET_PROJECS_SETTINGS_INFO_BEGIN:
            return {
                ...state,
                inProcess: true,
                data: {
                    project: {},
                    settings: {
                        general: {},
                        products: {},
                        languages: []
                    }
                }
            }
        case ActionType.GET_PROJECS_SETTINGS_INFO_SUCCESS:
            return {
                ...state,
                inProcess: false,
                data: action.payload
            }
        case ActionType.GET_PROJECS_SETTINGS_INFO_ERROR:
            return {
                ...state,
                error: action.payload,
                inProcess: false
            }
        default:
            return state;
    }
}

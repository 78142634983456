import { ActionType } from '../constants/ActionTypes';
import { ErrorType } from '../constants/Errors/ErrorTypes';
import { RoutePath } from '../constants/SitePaths';
import { LocalStorageService } from '../services/LocalStorageService';
import { notVerifiedAccount } from './account/VerificationActions';
import { RestPath } from '../constants/SitePaths';
import { Constants } from '../constants/Constants';
import axios from 'axios';
import history from '../services/History';
import { Auth } from '../services/Auth';
import config from '../constants/Configs';
import qs from 'qs'
import { notification } from 'antd';
let refreshTokenRequestSent = false;
let subscribers = []

// User Logout
export const logout = () =>
  ({
    type: ActionType.USER_LOGOUT
  });

// User Login 

export const resetLoading = () => ({
  type: ActionType.RESET_LOADING
});

export const loginUserBegin = () => ({
  type: ActionType.LOGIN_USER_BEGIN
});

export const loginUserSuccess = user => ({
  type: ActionType.LOGIN_USER_SUCCESS,
  payload: { user }
});

export const loginUserError = (user, error) => ({
  type: ActionType.LOGIN_USER_FAILURE,
  payload: { error: error, user: user }
});

//Get User Data
//Get User Data
export const getUserDataBegin = () => ({
  type: ActionType.GET_USER_DATA_BEGIN
})
export const getUserDataSuccess = (data) => ({
  type: ActionType.GET_USER_DATA_SUCCESS,
  payload: data
})
export const getUserDataError = (error) => ({
  type: ActionType.GET_USER_DATA_ERROR,
  payload: error
})
// User Registration
export const registerUserBegin = () => ({
  type: ActionType.REGISTER_USER_BEGIN
});

export const registerUserSuccess = user => ({
  type: ActionType.REGISTER_USER_SUCCESS,
  payload: { user }
});

export const registerUserError = (error) => ({
  type: ActionType.REGISTER_USER_FAILURE,
  payload: { error }
});

export const setPasswordBegin = () => ({
  type: ActionType.SET_PASSWORD_BEGIN
});

export const setPasswordSuccess = user => ({
  type: ActionType.SET_PASSWORD_SUCCESS,
  payload: { user }
});

export const setPasswordError = (error) => ({
  type: ActionType.SET_PASSWORD_ERROR,
  payload: error
});

export const checkHashBegin = () => ({
  type: ActionType.CHECK_HASH_BEGIN
});

export const checkHashError = user => ({
  type: ActionType.CHECK_HASH_SUCCESS,
  payload: { user }
});

export const checkHashSuccess = (error) => ({
  type: ActionType.CHECK_HASH_ERROR,
  payload: error
});


export const getLocationSuccess = (data) => ({
  type: ActionType.GET_LOCATION_SUCCESS,
  payload: data
})


export const updateAbilities = (abilities) => ({
  type: ActionType.UPDATE_ABILITIES,
  payload: { abilities }
})

export const getCompaniesBegin = () => ({
  type: ActionType.GET_COMPANIES_BEGIN
})
export const getCompaniesSuccess = (data) => ({
  type: ActionType.GET_COMPANIES_SUCCESS,
  payload: data
})
export const getCompaniesError = (error) => ({
  type: ActionType.GET_COMPANIES_ERROR,
  payload: error
})

export const getAllCompaniesBegin = () => ({
  type: ActionType.GET_ALL_COMPANIES_BEGIN
})
export const getAllCompaniesSuccess = (data) => ({
  type: ActionType.GET_ALL_COMPANIES_SUCCESS,
  payload: data
})
export const getAllCompaniesError = (error) => ({
  type: ActionType.GET_ALL_COMPANIES_ERROR,
  payload: error
})

// Two factor auth

export const generateTwoFactorAuthBegin = () => ({
  type: ActionType.GENERATE_TFA_CODE_BEGIN,
})

export const generateTwoFactorAuthSuccess = (data) => ({
  type: ActionType.GENERATE_TFA_CODE_SUCCESS,
  payload: data
})

export const generateTwoFactorAuthError = (error) => ({
  type: ActionType.GENERATE_TFA_CODE_FAILURE,
  payload: error
})


export const recoverTwoFactorAuthBegin = () => ({
  type: ActionType.RECOVER_TFA_BEGIN,
})

export const recoverTwoFactorAuthSuccess = (data) => ({
  type: ActionType.RECOVER_TFA_SUCCESS,
  payload: data
})

export const recoverTwoFactorAuthError = (error) => ({
  type: ActionType.RECOVER_TFA_FAILURE,
  payload: error
})



/** User Login */

export function loginUser(data) {
  Auth.invalidateSession();
  return dispatch => {

    dispatch(loginUserBegin());

    const params = {
      username: data.username,
      password: data.password,
      grant_type: "password",
      client_id: config.APP_CLIENT_ID
    };

    data.captchaCode && (params['captcha'] = data.captchaCode)
    data.TFACode && (params['TFACode'] = data.TFACode)
    //Encode the data
    const body = Object.keys(params).map((key) => {
      return encodeURIComponent(key) + '=' + encodeURIComponent(params[key]);
    }).join('&');
    return axios({
      url: config.BASE_URL + "/token",
      method: 'POST',
      headers: {
        'Content-Type': 'x-www-form-urlencoded',
      },
      data: body
    })
      .then(response => {
        LocalStorageService.set("authorizationData", response.data);
        dispatch(loginUserSuccess(response.data));

        return { status: 200, data: response.data };
      })
      .catch((error) => {
        if (error.response && error.response.status === 400 && error.response.data.code && (
          error.response.data.code === ErrorType.MOBILE_NOT_VERIFIED ||
          error.response.data.code === ErrorType.EMAIL_AND_MOBILE_NOT_VERIFIED ||
          error.response.data.code === ErrorType.EMAIL_NOT_VERIFIED
        )) {
          dispatch(loginUserError(params, error.response.data));
          dispatch(notVerifiedAccount(error.response && error.response.data.code));
          history.push(RoutePath.ACCOUNT_VERIFICATION);
        } else if (error.response) {
          dispatch(loginUserError(params, error.response.data));
        }

        return { status: 400, data: error.response && error.response.data };
      });
  };
}

function onAccessTokenFetched(access_token) {
  subscribers = subscribers.forEach(callback => callback(access_token));
}

function addSubscriber(callback) {
  subscribers.push(callback)
}

export function authorize(token) {
  return dispatch => {
    const params = `token=${token}`
    return axios({
      url: config.BASE_URL + RestPath.AUTHORIZE_GET + '?' + params,
      method: Constants.GET
    })
      .then(response => {
        LocalStorageService.set("authorizationData", response.data);
        return true;
      })
      .catch((error) => {
        return false;
      });
  }
}

export function refreshToken(authData, request) {
  const params = {
    refresh_token: authData.refreshToken,
    grant_type: 'refresh_token',
    client_secret: '748434cf2eeca18cd7be9661dce8c39d60f16461c8e760426958493b9f79b52f',
    client_id: config.APP_CLIENT_ID
  };

  //Encode the data
  // var body = new URLSearchParams();
  // Object.entries(params).forEach(([key, val]) => {
  //   body.set(key, encodeURI(val));
  // });

  if (!refreshTokenRequestSent) {
    refreshTokenRequestSent = true;
    axios({
      method: 'POST',
      url: config.BASE_URL + "/token",
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      data: qs.stringify(params),
    }).then((response) => {
      if (response.status < 400) {
        LocalStorageService.set("authorizationData", response.data);
      }
      refreshTokenRequestSent = false
      onAccessTokenFetched(response.data.access_token)
      subscribers = [];

      if (config.IS_SSO_ENABLED) {
        var accounFrameWindow = document.getElementById("account-frame").contentWindow;
        accounFrameWindow.postMessage(JSON.stringify({ key: 'authorizationData', method: 'update', data: response.data }), '*');
      }

      return response.data;
    }).catch((error) => {
      Auth.invalidateSession();
      history.push("/");
      refreshTokenRequestSent = false
      window.location.reload();
    });
  }

  const retryOriginalRequest = new Promise((resolve) => {
    addSubscriber(access_token => {
      request.headers.Authorization = 'Bearer ' + access_token
      resolve(axios(request))
    })
  })
  return retryOriginalRequest
}

export function getLocation() {
  return dispatch => {
    // dispatch(getLocationBegin());
    return axios({
      url: config.BASE_URL + RestPath.GET_LOCATION,
      method: Constants.GET
    })
      .then(response => {
        dispatch(getLocationSuccess(response.data))
        return response.data;
      })
      .catch((error) => {
        // dispatch(getLocationError(error.response && error.response.data));
      });
  };
}

/*  Get User Data  */
export function getUserData(params) {
  return dispatch => {

    dispatch(getUserDataBegin());

    return axios({
      url: `${config.BASE_URL}${RestPath.GET_USER_DATA}`,
      method: Constants.GET,
      data: JSON.stringify(params)
    }).then(response => {
      dispatch(getUserDataSuccess(response.data))
      return true;

    }).catch((error) => {
      dispatch(getUserDataError(error.response && error.response.data));
    })
  };
}

/* User Registration */
export function registerUser(data) {
  return dispatch => {
    dispatch(registerUserBegin());
    const params = {
      firstName: data.firstName,
      lastName: data.lastName,
      userName: data.email,
      mobileNumber: data.prefix + data.phone,
      password: data.password,
      confirmPassword: data.confirm,
      captchaCode: data.captchaCode,
      companyName: data.companyName
    };

    return axios({
      url: config.BASE_URL + "/Account/Register",
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      data: JSON.stringify(params)
    })
      .then(response => {
        // Success
        dispatch(registerUserSuccess(params));
        dispatch(notVerifiedAccount(ErrorType.EMAIL_AND_MOBILE_NOT_VERIFIED));
        history.push(RoutePath.ACCOUNT_VERIFICATION);
      })
      .catch((error) => {
        dispatch(registerUserError(params));
      });

  };
}

export function getCompanies(params, storeAsAllCompaines) {
  return dispatch => {

    dispatch(getCompaniesBegin());
    // const params = {
    //    'filter.companyId': 1	
    //   'filter.projectState': 2
    // }

    return axios({
      url: config.BASE_URL + RestPath.GET_USER_COMPANIES,
      method: Constants.GET,
      headers: {
        'Content-Type': 'application/json'
      },
      params
    })
      .then(response => {
        // Success
        dispatch(getCompaniesSuccess(response.data));
        storeAsAllCompaines && dispatch(getAllCompaniesSuccess(response.data));
      })
      .catch((error) => {
        dispatch(getCompaniesError(error.response && error.response.data));
      });

  };
}

export function getAllCompanies(params) {
  return dispatch => {

    dispatch(getAllCompaniesBegin());

    return axios({
      url: config.BASE_URL + RestPath.GET_ALL_USER_COMPANIES,
      method: Constants.GET,
      headers: {
        'Content-Type': 'application/json'
      },
      params
    })
      .then(response => {
        // Success
        dispatch(getAllCompaniesSuccess(response.data));
      })
      .catch((error) => {
        dispatch(getAllCompaniesError(error.response && error.response.data));
      });

  };
}

export function setPassword(data) {
  return dispatch => {

    dispatch(setPasswordBegin());

    return axios({
      url: config.BASE_URL + RestPath.CHANGE_PASSWORD_BY_URL,
      method: 'POST',
      data
    })
      .then(response => {
        // Success
        dispatch(setPasswordSuccess(response.data));
        return true;
      })
      .catch((error) => {
        dispatch(setPasswordError(error.response && error.response.data));
        return false;
      });

  };
}

export function checkHash(params) {
  return dispatch => {

    dispatch(checkHashBegin());

    return axios({
      url: config.BASE_URL + RestPath.CHECK_HASH,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      params
    })
      .then(response => {
        // Success
        dispatch(checkHashSuccess(response.data));
        return true;
      })
      .catch((error) => {
        dispatch(checkHashError(error.response && error.response.data));
        return false;
      });

  };
}

export function generateTwoFactorAuth(email) {
  return dispatch => {
    dispatch(generateTwoFactorAuthBegin());
    return axios({
      url: config.BASE_URL + RestPath.GENERATE_TFA_CODE,
      method: 'POST',
      data: {email}
    })
      .then(response => {
        dispatch(generateTwoFactorAuthSuccess(response.data));
        return true;
      })
      .catch((error) => {
        dispatch(generateTwoFactorAuthError(error.response && error.response.data));
        return false;
      });

  };
}

export function recoverTwoFactorAuth(email) {
  return dispatch => {
    dispatch(recoverTwoFactorAuthBegin());
    return axios({
      url: config.BASE_URL + RestPath.RECOVER_TFA,
      method: 'POST',
      data: {email}
    })
      .then(response => {
        dispatch(recoverTwoFactorAuthSuccess(response.data));
        notification.success({
          placement: 'bottomRight',
          duration: 5,
          message: 'QR code for 2 factor authentication has been sent to your email.',
          description: ''
        });
        return true;
      })
      .catch((error) => {
        dispatch(recoverTwoFactorAuthError(error.response && error.response.data));
        return false;
      });
  };
}
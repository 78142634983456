import React from 'react'
import { Auth } from './Auth';
import { LanguageService } from './LanguageService';
import { CustomErrors } from '../constants/Errors/CustomErrors';
import { ErrorType } from '../constants/Errors/ErrorTypes';
import { Utils } from './Utils';
import { notification } from 'antd';
import axios from 'axios';
import { refreshToken } from '../actions/UserActions';
import history from '../services/History';
import { Browser } from '../services/helpers/Browser'
import RolesHelper from './permisions/RolesHelper';

// Add a request interceptor
axios.interceptors.request.use(function (config) {
    // Do something before request is sent

    const authData = Auth.fillAndGetData();
    const selecteLanguage = LanguageService.getSelectedLanguage();

    const companyId = Browser.getURLParamValue("cid");
    const projectId = Browser.getURLParamValue("p");

    if (companyId) {
        //Set selected company id
        config.headers['CompanyId'] = companyId;
    }
    if (projectId) {
        //Set selected company id
        config.headers['ProjectId'] = projectId;
    }


    if (config.headers['grant_type'] !== 'refresh_token') {
        //Set access token
        if (authData && authData.token) {
            config.headers['Authorization'] = 'Bearer ' + authData.token;
        }

        //Set selected language
        if (selecteLanguage) {
            config.headers['accept-language'] = selecteLanguage;
        }

        config.headers['TimeZone'] = Utils.getTimeZone();
        //config.headers['TimeZone'] = Utils.getTimeZone() + '.0';
        
        //Set default content type
        if (!config.headers['Content-Type'] || config.headers['Content-Type'] === 'text/plain;charset=UTF-8') {
            config.headers['Content-Type'] = 'application/json';
        }
    } else {
        delete config.headers['grant_type'];
    }

    return config;
}, function (error) {
    // Do something with request error
    return Promise.reject(error);
});

// Add a response interceptor
axios.interceptors.response.use(function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
}, function (error) {
    console.log(error);
    console.log(error.response);
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    var authData = Auth.fillAndGetData();
    if (error && error.response && error.response.status === ErrorType.AUTH_ERROR) {

        const { config } = error
        const originalRequest = config

        if (authData && authData.refreshToken) {
            return refreshToken(authData, originalRequest);
        } else if (window.location.pathname !== "/sso") {
            Auth.invalidateSession();
            window.location.reload();
        }
    } else if (error && error.response && error.response.status >= 400) {
        const result = error.response && error.response.data;
        if (result && result.code && result.code === ErrorType.PROJECT_NOT_FOUND) {
            if (history.location.pathname !== RolesHelper.getRedirectPage()) {
                window.location.href = RolesHelper.getRedirectPage();
            }
        }
        if (!CustomErrors[result.code]) {
            // Handled error on backend side 
            if (result && result.code && result.message) {
                //Wrong autg token
                if (result.code === ErrorType.WRONG_AUTH_TOKEN) {
                    Auth.invalidateSession();
                    window.location.reload();
                }

                let description = '';
                if (result.errors && result.errors.length > 0) {
                    description = result.errors.map((elm) =>
                        <p key={elm.code.toString()}>{elm.message}</p>
                    );
                }

                notification.error({
                    key: 'networkError',
                    placement: 'bottomRight',
                    duration: 5,
                    message: result.message,
                    description: description
                });

            } else { // Case : unhandled error on backend side
                if (result.error && result.error === "invalid_grant" && window.location.pathname !== "/sso") {
                    Auth.invalidateSession();
                    window.location.reload();
                } else {
                    const selectednguage = LanguageService.getSelectedLanguage();
                    const translation = LanguageService.getTranslation(selectednguage);

                    notification.error({
                        key: 'generalError',
                        placement: 'bottomRight',
                        duration: 5,
                        message: translation.messages.general_error_msg_title,
                        description: translation.messages.general_error_msg_description
                    });
                }
            }
        }
    }
    return Promise.reject(error);
});

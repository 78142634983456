import { ActionType } from '../../constants/ActionTypes';

export default function announcmentReducer(state = {}, action = {}) {
    switch (action.type) {
        case ActionType.GET_ANNOUNCMENT_BEGIN:
        case ActionType.CHANGE_ANNOUNCMENT_VISIBILITY_BEGIN:
        case ActionType.DELETE_ANNOUNCMENT_BEGIN:
            return {
                ...state,
                inProcess: true
            }
        case ActionType.EDIT_ANNOUNCMENT_BEGIN:
        case ActionType.EDIT_ANNOUNCMENT_CONTENT_BEGIN:
        case ActionType.ADD_ANNOUNCMENT_BEGIN:
            return {
                ...state,
                saving: true
            }
        case ActionType.GET_ANNOUNCMENT_SUCCESS:
            return {
                ...state,
                data: action.payload,
                inProcess: false
            }
        case ActionType.GET_ANNOUNCMENT_ERROR:
        case ActionType.CHANGE_ANNOUNCMENT_VISIBILITY_ERROR:
        case ActionType.CHANGE_ANNOUNCMENT_VISIBILITY_SUCCESS:
        case ActionType.DELETE_ANNOUNCMENT_ERROR:
            return {
                ...state,
                inProcess: false
            }
        case ActionType.EDIT_ANNOUNCMENT_CONTENT_SUCCESS:
        case ActionType.EDIT_ANNOUNCMENT_SUCCESS:
        case ActionType.ADD_ANNOUNCMENT_SUCCESS:
        case ActionType.ADD_ANNOUNCMENT_ERROR:
        case ActionType.EDIT_ANNOUNCMENT_CONTENT_ERROR:
            case ActionType.EDIT_ANNOUNCMENT_ERROR:
            return {
                ...state,
                saving: false
            }
        case ActionType.GET_ANNOUNCMENT_SETTINGS_BEGIN:
        case ActionType.SET_ANNOUNCMENT_SETTINGS_BEGIN:
            return {
                ...state,
                settingsLoading: true
            }
        case ActionType.GET_ANNOUNCMENT_SETTINGS_ERROR:
        case ActionType.SET_ANNOUNCMENT_SETTINGS_ERROR:
        case ActionType.SET_ANNOUNCMENT_SETTINGS_SUCCESS:
            return {
                ...state,
                settingsLoading: false
            }
        case ActionType.GET_ANNOUNCMENT_SETTINGS_SUCCESS:
            return {
                ...state,
                settings:action.payload,
                settingsLoading: false
            }

        default:
            return state;
    }
}

import ActionType from '../../constants/ActionTypes';

export default function socialMediaReducer(state = {}, action = {}) {
    switch (action.type) {
        case ActionType.GET_SOCIAL_ITEMS_BEGIN:
            return {
                ...state,
                inSocialEditProcess: false,
                inProcess: true
            }

        case ActionType.GET_SOCIAL_ITEMS_ERROR:
            return {
                ...state,
                inProcess: false
            }

        case ActionType.GET_SOCIAL_ITEMS_SUCCESS:
            return {
                ...state,
                inProcess: false,
                socialMedia: action.payload
            }
        case ActionType.CHANGE_SOCIAL_VISIBILITY_BEGIN:
        case ActionType.EDIT_SOCIAL_ITEMS_BEGIN:
            return {
                ...state,
               inProcess: true
            }

        case ActionType.EDIT_SOCIAL_ITEMS_ERROR:
        case ActionType.CHANGE_SOCIAL_VISIBILITY_ERROR:
            return {
                ...state,
                socialsError: action.payload,
                inProcess: false
            }

        case ActionType.EDIT_SOCIAL_ITEMS_SUCCESS:
        case ActionType.CHANGE_SOCIAL_VISIBILITY_SUCCESS:
            return {
                ...state,
                socialMedia: action.payload,
                inProcess: false

            }

        case ActionType.MOVE_SOCIAL_ITEM_BEGIN:
        case ActionType.CHANGE_SOCIAL_SECTION_VISIBILITY_BEGIN:
            return {
                ...state,
                inSocialEditProcess: true
            }

        case ActionType.MOVE_SOCIAL_ITEM_ERROR:
        case ActionType.CHANGE_SOCIAL_SECTION_VISIBILITY_ERROR:
            return {
                ...state,
                socialsError: action.payload,
                inSocialEditProcess: false
            }

        case ActionType.MOVE_SOCIAL_ITEM_SUCCESS:
        case ActionType.CHANGE_SOCIAL_SECTION_VISIBILITY_SUCCESS:
            return {
                ...state,
                socialMedia: action.payload,
                inSocialEditProcess: false
            }

        default:
            return state;
    }
}
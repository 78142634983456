import { ErrorType } from './ErrorTypes';

export const CustomErrors = {};

CustomErrors[ErrorType.SITE_NAME_AlREADY_EXISTS] = ErrorType.SITE_NAME_AlREADY_EXISTS;
CustomErrors[ErrorType.COMPANY_NAME_AlREADY_EXISTS] = ErrorType.COMPANY_NAME_AlREADY_EXISTS;
CustomErrors[ErrorType.EMAIL_AND_MOBILE_NOT_VERIFIED] = ErrorType.EMAIL_AND_MOBILE_NOT_VERIFIED;
CustomErrors[ErrorType.EMAIL_NOT_VERIFIED] = ErrorType.EMAIL_NOT_VERIFIED;
CustomErrors[ErrorType.MOBILE_NOT_VERIFIED] = ErrorType.MOBILE_NOT_VERIFIED;
CustomErrors[ErrorType.WRONG_CURRENT_PASSWORD] = ErrorType.WRONG_CURRENT_PASSWORD;
CustomErrors[ErrorType.AUTH_ERROR] = ErrorType.AUTH_ERROR;
CustomErrors[ErrorType.QR_CODE_SETUP_REQUIRED] = ErrorType.QR_CODE_SETUP_REQUIRED;
CustomErrors[ErrorType.TFA_CODE_REQUIRED] = ErrorType.TFA_CODE_REQUIRED;

export default CustomErrors;
import { ActionType } from '../../../constants/ActionTypes';

export default function generalReducer(state = {}, action = {}) {
    switch (action.type) {
        case ActionType.STORE_PROJECT_GENERAL_SETTING:
            return {
                ...state,
                generalSettings: action.payload
            }
        case ActionType.GET_GENERAL_SETTINGS_BEGIN:
        case ActionType.GET_GENERAL_SETTINGS_ERROR:
        case ActionType.SET_GENERAL_SETTINGS_ERROR:
            return {
                ...state
            }
        case ActionType.SET_GENERAL_SETTINGS_BEGIN:
            return {
                ...state,
                inProcess: true,
            }
        case ActionType.GET_GENERAL_SETTINGS_SUCCESS:
        case ActionType.SET_GENERAL_SETTINGS_SUCCESS:
            return {
                ...state,
                general: action.payload
            }
        case ActionType.GET_CLIENT_MIN_AGE_BEGIN:
            return {
                ...state,
                minAge: 0,
                inLoadingProcess: true,
                inSavingProcess: false
            }
        case ActionType.GET_CLIENT_MIN_AGE_ERROR:
            return {
                ...state,
                error: action.payload,
                inLoadingProcess: false
            }
        case ActionType.GET_CLIENT_MIN_AGE_SUCCESS:
            return {
                ...state,
                minAge: action.payload,
                inLoadingProcess: false
            }          
        case ActionType.SET_CLIENT_MIN_AGE_BEGIN:
            return {
                ...state,
                inSavingProcess: true
            }
        case ActionType.SET_CLIENT_MIN_AGE_ERROR:
            return {
                ...state,
                error: action.payload,
                inSavingProcess: false
            }
        case ActionType.SET_CLIENT_MIN_AGE_SUCCESS:
            return {
                ...state,
                inSavingProcess: false
            }
        case ActionType.GET_REFERRAL_FRIEND_BEGIN:
            return {
                ...state,
                hours: 0,
                inLoadingProcess: true,
                inSavingProcess: false
            }
        case ActionType.GET_REFERRAL_FRIEND_ERROR:
            return {
                ...state,
                error: action.payload,
                inLoadingProcess: false
            }
        case ActionType.GET_REFERRAL_FRIEND_SUCCESS:
            return {
                ...state,
                hours: action.payload,
                inLoadingProcess: false
            } 
        case ActionType.SET_REFERRAL_FRIEND_BEGIN:
            return {
                ...state,
                inSavingProcess: true
            }
        case ActionType.SET_REFERRAL_FRIEND_ERROR:
            return {
                ...state,
                error: action.payload,
                inSavingProcess: false
            }
        case ActionType.SET_REFERRAL_FRIEND_SUCCESS:
            return {
                ...state,
                inSavingProcess: false
            }   
        case ActionType.GET_MARKETS_BEGIN:
            return {
                ...state,
                markets: [],
                inMarketLoadingProcess: true
            }
        case ActionType.GET_MARKETS_ERROR:
            return {
                ...state,
                inMarketLoadingProcess: false
            }
        case ActionType.GET_MARKETS_SUCCESS:
            return {
                ...state,
                markets: action.payload,
                inMarketLoadingProcess: false
            }
        case ActionType.SET_MARKET_BEGIN:
            return {
                ...state,
                inMarketSavingProcess: true
            }
        case ActionType.SET_MARKET_ERROR:
        case ActionType.SET_MARKET_SUCCESS:
            return {
                ...state,
                inMarketSavingProcess: false
            }
        default:
            return state;
    }
}

import React, { Suspense } from 'react';
import { Route } from 'react-router-dom'
import Can from '../services/permisions/Can'

/* Main Layout */
const EmptyLayout = ({ children, ...rest }) => {
  return (
    <>
      {children}
    </>
  )
}

/*
  Private route wrapper
 */

export const EmptyLayoutRoute = ({ component: Component, ...rest }) => {
  return (
    <Route {...rest} render={matchProps => (
      !rest.permissions ?
        <EmptyLayout>
          <Suspense fallback={<></>}>
            <Component {...matchProps} />
          </Suspense>
        </EmptyLayout> :
        <Can I={rest.permissions.type} a={rest.permissions.name} withRedirect={true} >
          <EmptyLayout>
            <Suspense fallback={<></>}>
              <Component {...matchProps} />
            </Suspense>
          </EmptyLayout>
        </Can>
    )} />
  )
};


import { ActionType } from '../../constants/ActionTypes'

export default function verificationReducer(state = {}, action = {}) {

    switch (action.type) {

        // Account is not veirfied
        case ActionType.NOT_VERIFIED_ACCOUNT:
            return {
                ...state,
                code: action.payload
            }

        // Account Email Verification
        case ActionType.VERIFY_ACCOUNT_EMAIL_BEGIN:
            return {
                ...state,
                email: {
                    ...state.email,
                    inProcess: true
                }
            }
        case ActionType.VERIFY_ACCOUNT_EMAIL_SUCCESS:
            return {
                ...state,
                email: {
                    ...state.email,
                    inProcess: false,
                    verified: true
                }
            }
        case ActionType.VERIFY_ACCOUNT_EMAIL_FAILURE:
            return {
                ...state,
                email: {
                    ...state.email,
                    inProcess: false,
                    error: action.payload.error
                }
            }
        // Resend Email Verification
        case ActionType.RESEND_EMAIL_VERIFICATION_CODE_BEGIN:
            return {
                ...state,
                email: {
                    ...state.email,
                    resendVerificationCodeInProcess: true
                }
            }
        case ActionType.RESEND_EMAIL_VERIFICATION_CODE_SUCCESS:
            return {
                ...state,
                email: {
                    ...state.email,
                    resendVerificationCodeInProcess: false
                }
            }
        case ActionType.RESEND_EMAIL_VERIFICATION_CODE_FAILURE:
            return {
                ...state,
                email: {
                    ...state.email,
                    resendVerificationCodeInProcess: false,
                    error: action.payload.error
                }
            }

        // User Mobile Verification
        case ActionType.VERIFY_ACCOUNT_MOBILE_BEGIN:
            return {
                ...state,
                mobile: {
                    ...state.mobile,
                    inProcess: false
                }
            }
        case ActionType.VERIFY_ACCOUNT_MOBILE_SUCCESS:
            return {
                ...state,
                mobile: {
                    ...state.mobile,
                    inProcess: false,
                    verified: true
                }
            }
        case ActionType.VERIFY_ACCOUNT_MOBILE_FAILURE:
            return {
                ...state,
                mobile: {
                    ...state.mobile,
                    inProcess: false,
                    error: action.payload.error
                }
            }

        // Resend Mobile Verification
        case ActionType.RESEND_MOBILE_VERIFICATION_CODE_BEGIN:
            return {
                ...state,
                mobile: {
                    ...state.mobile,
                    resendVerificationCodeInProcess: true
                }
            }
        case ActionType.RESEND_MOBILE_VERIFICATION_CODE_SUCCESS:
            return {
                ...state,
                mobile: {
                    ...state.mobile,
                    resendVerificationCodeInProcess: false
                }
            }
        case ActionType.RESEND_MOBILE_VERIFICATION_CODE_FAILURE:
            return {
                ...state,
                mobile: {
                    ...state.mobile,
                    resendVerificationCodeInProcess: false,
                    error: action.payload.error
                }
            }
        default:
            return state;
    }

}


import { LocalStorageService } from "./LocalStorageService"
/** Authentication namespace
 * @namespace
 */
export const Auth = {

  isLoggedIn: false,
  token: null,
  userName: null,
  refreshToken: null,

  /** Check is user authenticated or not */
  isAuthenticated: function () {

    const authData = LocalStorageService.get('authorizationData');

    if (authData && authData.access_token) {

      this.isLoggedIn = true;
      this.role = authData.superAdmin;
      this.token = authData.access_token;
      this.refreshToken = authData.refresh_token;

      return true;

    } else {
      return false;
    }
  },

  /** 
    * @typedef AuthData
    * @property {boolean} isLoggedIn Is user loged in or not
    * @property {string} token Token
    * @property {string} refreshToken Refresh token
    */

  /** Get authenticated user's data
   * @returns {AuthData}*/

  fillAndGetData: function () {

    const authData = LocalStorageService.get("authorizationData");

    if (authData) {
      this.isLoggedIn = true;
      this.token = authData.access_token;
      this.refreshToken = authData.refresh_token;
    }
    return this;
  },

  /** Get authenticated user's data
 * @returns {AuthData}*/

  setAuthData: function (authData) {

    LocalStorageService.set("authorizationData", authData);

    if (authData) {
      this.isLoggedIn = true;
      this.token = authData.access_token;
      this.refreshToken = authData.refresh_token;
    }
    return this;
  },

  /** Invalidate user session*/
  invalidateSession: function () {
    LocalStorageService.remove("authorizationData");
    LocalStorageService.remove("role");
  },

  setRole: function (role) {
    LocalStorageService.set("role", role);
  },

  getRole: function (role) {
    return LocalStorageService.get('role');
  },

  // TODO change checking logic
  checkPermission: function () {
    return LocalStorageService.get('role');
  }

}
import { ActionType } from '../../../constants/ActionTypes';

export default function imageBlocksReducer(state = {}, action = {}) {
    switch (action.type) {
        case ActionType.GET_IMAGE_BLOCKS_BEGIN:
        case ActionType.CHANGE_IMAGE_BLOCKS_VISIBILITY_BEGIN:
        case ActionType.MOVE_IMAGE_BLOCKS_VISIBILITY_BEGIN:
        case ActionType.DELETE_IMAGE_BLOCK_BEGIN:
            return {
                ...state,
                inProcess: true
            }
        case ActionType.EDIT_IMAGE_BLOCK_BEGIN:
        case ActionType.ADD_IMAGE_BLOCKS_BEGIN:
            return {
                ...state,
                saving: true
            }
        case ActionType.GET_IMAGE_BLOCKS_SUCCESS:
            return {
                ...state,
                imageBlockData: action.payload,
                inProcess: false
            }
        case ActionType.GET_IMAGE_BLOCKS_ERROR:
        case ActionType.CHANGE_IMAGE_BLOCKS_VISIBILITY_ERROR:
        case ActionType.CHANGE_IMAGE_BLOCKS_VISIBILITY_SUCCESS:
        case ActionType.MOVE_IMAGE_BLOCKS_VISIBILITY_SUCCESS:
        case ActionType.MOVE_IMAGE_BLOCKS_VISIBILITY_ERROR:
        case ActionType.DELETE_IMAGE_BLOCK_SUCCESS:
        case ActionType.DELETE_IMAGE_BLOCK_ERROR:
            return {
                ...state,
                inProcess: false
            }
        case ActionType.ADD_IMAGE_BLOCKS_ERROR:
        case ActionType.ADD_IMAGE_BLOCKS_SUCCESS:
        case ActionType.EDIT_IMAGE_BLOCK_SUCCESS:
        case ActionType.EDIT_IMAGE_BLOCK_ERROR:
            return {
                ...state,
                saving: false
            }
        default:
            return state;
    }
}

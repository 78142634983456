import ActionType from '../../constants/ActionTypes';

export default function uiSettingsReducer(state = {}, action = {}) {
    switch (action.type) {
        case ActionType.GET_UI_SETTINGS_ERROR:
            return {
                ...state
            }
        case ActionType.GET_UI_SETTINGS_SUCCESS:
            const { settings, ...rest } = action.payload
            return {
                ...state,
                configs: {
                    ...state.configs,
                    allSettings: [...settings],
                    currentSettings: {
                        ...state.configs.currentSettings,
                        ...rest,
                        settings: {
                            ...(settings?.find((elem) => elem.modeId === null)?.settings || {})
                        }
                    }
                }
            }
        case ActionType.GET_UI_SETTINGS_BEGIN:
        case ActionType.SET_UI_SETTINGS_ERROR:
        case ActionType.SET_UI_SETTINGS_SUCCESS:
            return {
                ...state,
                configs: {
                    ...state.configs,
                    currentSettings: {
                        ...state.configs.currentSettings,
                        isSaveInProcess: false
                    }
                }
            }
        case ActionType.SET_UI_SETTINGS_BEGIN:
            return {
                ...state,
                configs: {
                    ...state.configs,
                    currentSettings: {
                        ...state.configs.currentSettings,
                        isSaveInProcess: true
                    }
                }
            }
        case ActionType.SET_UI_SETTING:

            return {
                ...state,
                configs: {
                    ...state.configs,
                    currentSettings: {
                        ...state.configs.currentSettings,
                        settings: {
                            ...state.configs.currentSettings.settings,
                            [action.payload.name]: action.payload.value
                        }
                    }
                }
            }
        case ActionType.SET_UI_SETTINGS:
            return {
                ...state,
                configs: {
                    ...state.configs,
                    allSettings: [...state.configs.allSettings.map((elem) => elem.modeId === action.payload.modeId ? { ...elem, settings: { ...action.payload } } : elem)],
                    currentSettings: {
                        ...state.configs.currentSettings,
                        settings: {
                            ...state.configs.currentSettings.settings,
                            ...action.payload
                        }
                    }
                }
            }
        case ActionType.SET_UI_GROUP:
            return {
                ...state,
                configs: {
                    ...state.configs,
                    currentSettings: {
                        ...state.configs.currentSettings,
                        groupId: action.payload
                    }
                }
            }
        case ActionType.CHANGE_MENU_LANGUAGE:
            return {
                ...state,
                languageId: action.payload
            }
        case ActionType.GET_POPUPS_COLOR_MODE_BEGIN:
            return {
                ...state,
                configs: {
                    ...state.configs,
                    currentSettings: {
                        ...state.configs.currentSettings,
                        isPopupColorModeLoading: true
                    }
                }
            }
        case ActionType.GET_POPUPS_COLOR_MODE_SUCCESS:
        case ActionType.GET_POPUPS_COLOR_MODE_ERROR:
            return {
                ...state,
                configs: {
                    ...state.configs,
                    currentSettings: {
                        ...state.configs.currentSettings,
                        popupColorMode: action.payload,
                        isPopupColorModeLoading: false
                    }
                }

            }
        case ActionType.SET_COLOR_MODE_SETTINGS:
            return {
                ...state,
                configs: {
                    ...state.configs,
                    currentSettings: {
                        ...state.configs.currentSettings,
                        colorModeSettings: { 
                            ...action.payload, 
                            defaultColorMode: action.payload.colorModeId 
                        }
                    }
                }

            }
            case ActionType.CHANGE_BRANDING_SETTINGS_STATE:
            return {
                ...state,
                configs: {
                    ...state.configs,
                    isBrandingSettingsChanged: action.payload
                }
            }
            case ActionType.CHANGE_WEBSITE_COLOR_MODE:
            return {
                ...state,
                configs: {
                    ...state.configs,
                    currentSettings: {
                        ...state.configs.currentSettings,
                        colorModeSettings: { 
                            ...state.configs.currentSettings.colorModeSettings, 
                            ...action.payload
                        }
                    }
                }
            }
            case ActionType.SET_BRANDING_INITIAL_SETTINGS:
            return {
                ...state,
                configs: {
                    ...state.configs,
                    currentSettings: {
                        ...state.configs.currentSettings,
                        initSettings: action.payload
                    }
                }
            }
        default:
            return state;
    }
}
import { en } from '../translations';
import { APP_VERSION } from './Configs';
import { LocalStorageService } from '../services/LocalStorageService';
import { templateControls } from './state/TemplateControls';

const appVersion = LocalStorageService.get('app_version');
if (parseFloat(appVersion) !== APP_VERSION) {
  LocalStorageService.remove('saved_store_state');
  LocalStorageService.set('app_version', APP_VERSION);
}

const authData = LocalStorageService.get('authorizationData') || {};


export const initialState = {
  intl: en,
  user: {
    id: null,
    email: null,
    firstName: null,
    lastName: null,
    mobileNumber: null,
    permissions: {},
    superAdmin: false,
    company: {},
    error: "",
    location: "",
    isLoggedIn: false,
    isLoading: false,
    ...authData
  },

  builder: {
    projects: {
      inProcess: false,
      allProjects: [],
      selectedProject: {},
      companies: {},
      allCompanies: {},
      newProject: {
        inProcess: false,
        error: null,
        currentStep: 0,
        name: '',
        templateId: 1
      },
    },
    templates: {
      inProgress: false,
      error: {},
      list: []
    },
    settings: {
      current: {
        addOns: {
          data: {}
        },
        externalServices: {
          data: [],
          serviceDetails: {}
        },
        customCodes: {
          editableScript: {},
          scripts: [],
          styles: []
        },
        captcha: {
          data: {}
        },
        languages: {
          data: []
        },
        licenses: {
          allLicenses: [],
          licenseSettings: [],
        },
        currencies: {
          data: []
        },
        products: {
          data: {
            3: {
              state: {}
            },
            42: {
              data: []
            }
          },
          casino: {
            data: []
          },
          providers: {
            data: []
          },
          categories: []
        },
        payments: {
          data: []
        },
        general: {
          markets: []
        },
        domains: {
          data: {}
        },
        storyBlocks: {
          storyBlockData: []
        },
        overview: {
          data: {
            project: {},
            settings: {
              general: {},
              products: {},
              languages: []
            }
          }
        },
        logos: {
          data: {
            logoUrl: {},
            smallLogoUrl: {},
            faviconUrl: {},
            logoLink: {}
          }
        },
        banners: {
          sections: [],
          data: []
        },
        imageBlocks: {
          imageBlocksList: {
            nestedControls: []
          },
          saving: false
        },
        seo: {
          settings: {
            pages: [],
          },
          social: {
            twitter: [],
            openGraph: []
          },
          schema: {}
        },
        businessRules: {
          loading: false,
          saving:false,
          isRuleLoading:false,
          data: [],
          currentRule:{}
        },
        devTools:{
          isLoading:false
        },
        appAdviser:{
          inProcess:false,
          data:{}
        },
        mobileApps:{
          inProcess:false,
          data:{}
        }
      },
      allSettings: {
        data: {
          languages: [],
          currencies: [],
          captcha: [],
          paymentSystems: [],
          externalServices: []
        }
      }
    },
    ui: {
      deviceMode: 0,
      socialMedia: {
        inProcess: false,
        socialMedia: {
          isVisible: true,
          nestedControls: []
        }
      },
      timerControl: {
        isLoading: false,
        isVisible: true,
        showTimeZone: true,
        position: null,
        section: null,
      },
      paymentControl: {
        inProcess: false,
        isVisible: true,
        paymentControl: {
          inProcess: false,
          isVisible: true,
          nestedControls: []
        },
        availablePayments: {}
      },
      settings: {
        languageId: '*',
        configs: {
          isLoading: false,
          currentSettings: {
            settings: {}
          },
          isBrandingSettingsChanged: false
        },
        groupId: 1,
        projectId: 1
      },
      template: templateControls,
      controls: {
        registrationVerification: {},
      },
      featuredProducts: {
        inProcess: false,
        featuredProductsData: {
          nestedControls: []
        }
      },
      accessButtons: {
        inProcess: false,
        data: {
        }
      },
      providers: {

        inProcess: false,
        providers: {
          isVisible: false,
          nestedControls: []
        },
        availableProviders: {}
      },
      uiControls: {

      },
      iconSet: {
      },
      promotions: {
        categories: [],
        promotionsData: [],
        highlighteLoading: false
      },
      SEORules: {
        loading: false,
        saving:false,
        isRuleLoading:false,
        data: [],
        currentRule:{}
      },
    },
    account: {
      roles: {
        loading: false,
        data: []
      },
      users: {
        loading: false,
        data: []
      }
    }
  },

  verification: {
    code: 0,
    email: {
      verified: false,
      inProcess: false,
      resendVerificationCodeInProcess: false,
      error: []
    },
    mobile: {
      verified: true,
      inProcess: false,
      resendVerificationCodeInProcess: false,
      error: []
    }
  }
};

export default initialState;
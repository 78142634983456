import React, { Suspense } from 'react';
import { Route, Redirect } from 'react-router-dom'
import { Layout } from 'antd';
import SiteHeader from '../components/header';
import Can from '../services/permisions/Can'
import RolesHelper from '../services/permisions/RolesHelper';

const { Content } = Layout;

/* Main Layout */
const MainLayout = ({ children, ...rest }) => {
  return (
    <Layout className='tb--h100 tb--projects'>
      <SiteHeader></SiteHeader>
      <Layout>
        <Layout>
          <Content>
            {children}
          </Content>
        </Layout>
      </Layout>
    </Layout>
  )
}

/*
  Private route wrapper
 */

export const MainLayoutRoute = ({ component: Component, ...rest }) => {
  return (
    <Route {...rest} render={matchProps => (
      RolesHelper.isAffilateUser() ? <Redirect to={RolesHelper.getRedirectPage()} />
        : !rest.permissions ?
          <MainLayout>
            <Suspense fallback={<></>}>
              <Component {...matchProps} />
            </Suspense>
          </MainLayout> :
          <Can I={rest.permissions.type} a={rest.permissions.name} withRedirect={true} >
            <MainLayout>
            <Suspense fallback={<></>}>
              <Component {...matchProps} />
            </Suspense>
            </MainLayout>
          </Can>
    )} />
  )
};


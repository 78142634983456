// import React from 'react';
import React from 'react';
import { Route } from 'react-router-dom'
import { Layout, Row, Col } from 'antd';
import Copyright from '../components/footer/Copyright';
// import Particles from 'react-particles-js';
import { Suspense } from 'react';
const Particles = React.lazy(() => import('react-particles-js'))

const { Content } = Layout;

/* Login Layout */
const LoginLayout = ({ children, ...rest }) => {
  return (
    <div className="account_layout">
      <Suspense fallback={<></>}>
      <Particles className="particle-canvas" params={{
        "particles": {
          "number": {
            "value": 149,
            "density": {
              "enable": true,
              "value_area": 800
            }
          },
          "color": {
            "value": "#ffffff"
          },
          "shape": {
            "type": "circle",
            "stroke": {
              "width": 0,
              "color": "#000000"
            },
            "polygon": {
              "nb_sides": 3
            },
            "image": {
              "src": "img/github.svg",
              "width": 100,
              "height": 100
            }
          },
          "opacity": {
            "value": 0.5,
            "random": false,
            "anim": {
              "enable": false,
              "speed": 1,
              "opacity_min": 0.1,
              "sync": false
            }
          },
          "size": {
            "value": 6,
            "random": true,
            "anim": {
              "enable": false,
              "speed": 40,
              "size_min": 0,
              "sync": false
            }
          },
          "line_linked": {
            "enable": true,
            "distance": 160.3412060865523,
            "color": "#ffffff",
            "opacity": 0.4,
            "width": 0.4810236182596568
          },
          "move": {
            "enable": true,
            "speed": 2,
            "direction": "none",
            "random": false,
            "straight": false,
            "out_mode": "out",
            "bounce": false,
            "attract": {
              "enable": false,
              "rotateX": 160.3412060865523,
              "rotateY": 1200
            }
          }
        },
        "interactivity": {
          "detect_on": "canvas",
          "events": {
            "onhover": {
              "enable": true,
              "mode": "grab"
            },
            "onclick": {
              "enable": true,
              "mode": "push"
            },
            "resize": true
          },
          "modes": {
            "grab": {
              "distance": 400,
              "line_linked": {
                "opacity": 1
              }
            },
            "bubble": {
              "distance": 400,
              "size": 40,
              "duration": 2,
              "opacity": 8,
              "speed": 3
            },
            "repulse": {
              "distance": 200,
              "duration": 0.4
            },
            "push": {
              "particles_nb": 4
            },
            "remove": {
              "particles_nb": 2
            }
          }
        },
        "retina_detect": true
      }} /></Suspense>
      <Row gutte={5} className="header">
        <Col span={20}>
          {/* <Link to="/" className="logo" /> */}
        </Col>
        <Col span={4}>
          <div className="float-right">
            {/* <Languages></Languages> */}
          </div>
        </Col>
      </Row>
      <Content >
        <div className='tb--modal-lvl1'>
          <div className='tb--modal-lvl2'>
            <div className='tb--modal-lvl3'>
              <div className="form-container" >
                <div className="logo">
                </div>
                <div className="form-group">
                  <div className="main">{children}</div>
                </div>
                <Copyright />
              </div>
            </div>
          </div>
        </div>

      </Content>
    </div>
  )
}

/*
  Route wrapper
 */
export const LoginLayoutRoute = ({ component: Component, ...rest }) => {
  return (
    <Route {...rest} render={matchProps => (
      <LoginLayout>
        <Suspense fallback={<></>}>
          <Component {...matchProps} />
        </Suspense>
      </LoginLayout>
    )} />
  )
};
import { ActionType } from '../../../constants/ActionTypes';


export default function seoReducer(state = {}, action = {}) {
    switch (action.type) {



        case ActionType.GET_SEO_PAGES_BEGIN:
            return {
                ...state,
                settings: {
                    ...action.payload,
                    sections: [],
                },
                pagesLoading: true
            }
        case ActionType.GET_SEO_PAGES_ERROR:
            return {
                ...state,
                pagesError: action.payload,
                pagesLoading: false

            }
        case ActionType.GET_SEO_PAGES_SUCCESS:
            return {
                ...state,
                settings: {
                    sections: action.payload,
                },
                pagesLoading: false
            }
        case ActionType.GET_SEO_SETTINGS_SUCCESS:
            return {
                ...state,
                settings: {
                    sections: state.settings.sections,
                    ...action.payload

                },
                settingsLoading: false,
                saving: false
            }
        case ActionType.GET_SEO_SETTINGS_ERROR:
            return {
                ...state,
                settingsLoading: false,
                settingsError: action.payload,
                saving: false
            }

        case ActionType.GET_SEO_SETTINGS_BEGIN:
            return {
                ...state,
                settingsLoading: true,
                saving: false
            }
        case ActionType.SET_SEO_SETTINGS_BEGIN:
            return {
                ...state,
                saving: true,
                settingsLoading: true,
            }
        case ActionType.SET_SEO_SETTINGS_ERROR:
            return {
                ...state,
                saving: false,
                settingsLoading: false,
            }
        case ActionType.SET_SEO_SETTINGS_SUCCESS:
            return {
                ...state,
                saving: false,
                settingsLoading: false
            }
        case ActionType.GET_OPEN_GRAPH_SETTINGS_BEGIN:
            return {
                ...state,
                openGraphLoading: true,
                saving: false
            }
        case ActionType.GET_OPEN_GRAPH_SETTINGS_ERROR:
            return {
                ...state,
                openGraphLoading: false,
                settingsError: action.payload,
                saving: false
            }
        case ActionType.GET_OPEN_GRAPH_SETTINGS_SUCCESS:
            return {
                ...state,

                social: {
                    ...state.social,
                    openGraph: action.payload

                },
                openGraphLoading: false,
                saving: false
            }
        case ActionType.GET_TWITTER_SETTINGS_BEGIN:
            return {
                ...state,
                twitterLoading: true,
                saving: false
            }
        case ActionType.GET_TWITTER_SETTINGS_ERROR:
            return {
                ...state,
                twitterLoading: false,
                settingsError: action.payload,
                saving: false
            }
        case ActionType.GET_TWITTER_SETTINGS_SUCCESS:
            return {
                ...state,

                social: {
                    ...state.social,
                    twitter: action.payload
                },
                twitterLoading: false,
                saving: false
            }
        case ActionType.SET_TWITTER_SETTINGS_BEGIN:
            return {
                ...state,
                saving: true,
                twitterLoading: true,
            }
        case ActionType.SET_TWITTER_SETTINGS_ERROR:
            return {
                ...state,
                saving: false,
                twitterLoading: false,
            }
        case ActionType.SET_TWITTER_SETTINGS_SUCCESS:
            return {
                ...state,
                saving: false,
                twitterLoading: false
            }
        case ActionType.SET_OPEN_GRAPH_SETTINGS_BEGIN:
            return {
                ...state,
                saving: true,
                openGraphLoading: true,
            }
        case ActionType.SET_OPEN_GRAPH_SETTINGS_ERROR:
            return {
                ...state,
                saving: false,
                openGraphLoading: false,
            }
        case ActionType.SET_OPEN_GRAPH_SETTINGS_SUCCESS:
            return {
                ...state,
                saving: false,
                openGraphLoading: false
            }
        case ActionType.SET_SEO_SCHEMA_SETTINGS_BEGIN:
            return {
                ...state,
                saving: true,
                schemaLoading: true,
            }
        case ActionType.SET_SEO_SCHEMA_SETTINGS_ERROR:
            return {
                ...state,
                saving: false,
                schemaLoading: false,
            }
        case ActionType.SET_SEO_SCHEMA_SETTINGS_SUCCESS:
            return {
                ...state,
                saving: false,
                schemaLoading: false
            }
        case ActionType.GET_SEO_SCHEMA_SETTINGS_BEGIN:
            return {
                ...state,
                schemaLoading: true,
                saving: false
            }
        case ActionType.GET_SEO_SCHEMA_SETTINGS_ERROR:
            return {
                ...state,
                schemaLoading: false,
                settingsError: action.payload,
                saving: false
            }
        case ActionType.GET_SEO_SCHEMA_SETTINGS_SUCCESS:
            return {
                ...state,
                schema: action.payload,
                schemaLoading: false,
                saving: false
            }
        case ActionType.GET_SITEMAP_SETTINGS:
            return {
                ...state,
                data: action.payload,
            }
        case ActionType.SET_SITEMAP_SETTINGS:
            return {
                ...state,
                data: action.payload,
            }
        default:
            return state;
    }
}

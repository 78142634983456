import { ActionType } from '../../../constants/ActionTypes';

export default function appAdviserReducer(state = {}, action = {}) {
    switch (action.type) {
        case ActionType.GET_APP_ADVISOR_BEGIN:
        case ActionType.SET_APP_ADVISOR_BEGIN:
            return {
                ...state,
                inProcess: true
            }
   
        case ActionType.GET_APP_ADVISOR_SUCCESS:
            return {
                ...state,
                data: action.payload,
                inProcess: false
            }
        case ActionType.SET_APP_ADVISOR_SUCCESS:
        case ActionType.GET_APP_ADVISOR_ERROR:
        case ActionType.SET_APP_ADVISOR_ERROR:
            return {
                ...state,
                inProcess: false
            }
        default:
            return state;
    }
}

import { ActionType } from '../../constants/ActionTypes';


export default function popupsReducer(state = {}, action = {}) {
    switch (action.type) {
        case ActionType.GET_POPUPS_BEGIN:
            return {
                ...state,
                loading: true,
                data: []
            }
        case ActionType.GET_POPUPS_ERROR:
            return {
                ...state,
                loading: false
            }
        case ActionType.GET_POPUPS_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload
            }

        case ActionType.GET_POPUP_CONTENTS_BEGIN:
            return {
                ...state,
                isContentsloading: true,
                pageContents: [],
                popupHistory: []
            }
        case ActionType.GET_POPUP_CONTENTS_ERROR:
            return {
                ...state,
                isContentsloading: false
            }
        case ActionType.GET_POPUP_CONTENTS_SUCCESS:
            return {
                ...state,
                isContentsloading: false,
                pageContents: action.payload
            }
        case ActionType.GET_POPUP_CONTENT_BEGIN:
            return {
                ...state,
                isContentloading: true,
                popupContent: []
            }
        case ActionType.GET_POPUP_CONTENT_ERROR:
            return {
                ...state,
                isContentloading: false
            }
        case ActionType.GET_POPUP_CONTENT_SUCCESS:
            return {
                ...state,
                isContentloading: false,
                popupContent: action.payload
            }

        case ActionType.GET_POPUP_SETTINGS_BEGIN:
            return {
                ...state,
                isSettingLoading: true
            }
        case ActionType.GET_POPUP_SETTINGS_ERROR:
            return {
                ...state,
                isSettingLoading: false
            }
        case ActionType.GET_POPUP_SETTINGS_SUCCESS:
            return {
                ...state,
                isSettingLoading: false,
                settings: action.payload
            }
        case ActionType.SAVE_POPUP_SETTINGS_BEGIN:
        case ActionType.ADD_POPUP_BEGIN:
            return {
                ...state,
                saving: true
            }
        case ActionType.SAVE_POPUP_SETTINGS_ERROR:
        case ActionType.SAVE_POPUP_SETTINGS_SUCCESS:
        case ActionType.ADD_POPUP_ERROR:
        case ActionType.ADD_POPUP_SUCCESS:
            return {
                ...state,
                saving: false
            }
        case ActionType.EDIT_POPUP_BEGIN:
            return {
                ...state,
                isPageloading: true,
            }
        case ActionType.EDIT_POPUP_ERROR:
        case ActionType.EDIT_POPUP_SUCCESS:

            return {
                ...state,
                isPageloading: false,
            }
        case ActionType.SAVE_POPUP_CONTENT_BEGIN:
            return {
                ...state,
                isContentSaving: true,
            }
        case ActionType.SAVE_POPUP_CONTENT_ERROR:
        case ActionType.SAVE_POPUP_CONTENT_SUCCESS:
            return {
                ...state,
                isContentSaving: false,
            }

        case ActionType.GET_DEFAULT_POPUPS_BEGIN:
            return {
                ...state,
                isLoading: true
            }
        case ActionType.GET_DEFAULT_POPUPS_ERROR:
            return {
                ...state,
                isLoading: false
            }
        case ActionType.GET_DEFAULT_POPUPS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                defaultPopups: action.payload.defaultPopups
            }

        case ActionType.DELETE_POPUP_BEGIN:
            return {
                ...state,
                deleting: true
            }
        case ActionType.DELETE_POPUP_ERROR:
        case ActionType.DELETE_POPUP_SUCCESS:
            return {
                ...state,
                deleting: false
            }

        case ActionType.GET_TEMPLATE_BEGIN:
            return {
                ...state,
                isTemplateLoading: true
            }
        case ActionType.GET_TEMPLATE_ERROR:
            return {
                ...state,
                isTemplateLoading: false
            }
        case ActionType.GET_TEMPLATE_SUCCESS:
            return {
                ...state,
                isTemplateLoading: false,
                template: action.payload
            }
        case ActionType.EDIT_TEMPLATE_CONTENT_BEGIN:
            return {
                ...state
            }
        case ActionType.EDIT_TEMPLATE_CONTENT_ERROR:
            return {
                ...state
            }
        case ActionType.EDIT_TEMPLATE_CONTENT_SUCCESS:
            return {
                ...state
            }
        default:
            return state;
    }
}

import { ActionType } from '../../../constants/ActionTypes';

export default function domainsReducer(state = {}, action = {}) {

    switch (action.type) {
        case ActionType.ALL_DOMAINS_ON_LOAD:
            return {
                ...state,
                inProcess: false
            }
        case ActionType.ADD_DOMAIN_BEGIN:
            return {
                ...state,
                inProcess: true,
                addDomainError: false,
                addDomainSuccess: false
            }
        case ActionType.ADD_DOMAIN_ERROR:
            return {
                ...state,
                inProcess: false,
                addDomainError: true
            }
        case ActionType.ADD_DOMAIN_SUCCESS:
            return {
                ...state,
                inProcess: false,
                addDomainSuccess: true
            }
        case ActionType.REMOVE_DOMAIN_BEGIN:
            return {
                ...state,
                deleteSuccess: false,
                deleteError: false,
                data: {
                    ...state.data,
                    [action.payload]: {
                        ...state.data[action.payload],
                        deleteInProcess: true
                    }
                }

            }
        case ActionType.GET_ALL_DOMAINS_BEGIN:
            return {
                ...state,
                deleteError: false,
                deleteInProcess: false,
                inLoadingProcess: true,
            }
        case ActionType.GET_ALL_DOMAINS_SUCCESS:
            return {
                ...state,
                data: action.payload.domains,
                domainsLimit: action.payload.domainsCount,
                inLoadingProcess: false,
            }
        case ActionType.GET_ALL_DOMAINS_ERROR:
            return {
                ...state,
                inLoadingProcess: false,
            }

        case ActionType.REMOVE_DOMAIN_ERROR:

            return {
                ...state,
                deleteError: true,
                deleteInProcess: false,
                data: {
                    ...state.data,
                    [action.payload]: {
                        ...state.data[action.payload],
                        deleteInProcess: false
                    }
                }
            }
        case ActionType.REMOVE_DOMAIN_SUCCESS:

            const { [action.payload]: omit, ...updatedDomains } = state.data

            return {
                ...state,
                deleteError: false,
                deleteInProcess: false,
                data: updatedDomains
            }
        //Verify domain
        case ActionType.DOMAIN_ACTIVATION_CHECK_BEGIN:
            return {
                ...state,
                data: {
                    ...state.data,
                    [action.payload]: {
                        ...state.data[action.payload],
                        verifyInProcess: true
                    }
                }
            }

        case ActionType.DOMAIN_ACTIVATION_CHECK_ERROR:
        case ActionType.DOMAIN_ACTIVATION_CHECK_SUCCESS:
            return {
                ...state,
                data: {
                    ...state.data,
                    [action.payload]: {
                        ...state.data[action.payload],
                        verifyInProcess: false
                    }
                }
            }
        case ActionType.DOMAIN_PROCESSING_BEGIN:
            return {
                ...state,
                isSaving: true
            }
        case ActionType.DOMAIN_PROCESSING_END:
            return {
                ...state,
                isSaving: false
            }
        case ActionType.CHANGE_DOMAIN_BEGIN:
            return {
                ...state,
                inProcess: true
            }
        case ActionType.CHANGE_DOMAIN_SUCCESS:
            return {
                ...state,
                inProcess: false
            }
            
        case ActionType.CHANGE_DOMAIN_ERROR:
            return {
                ...state,
                inProcess: false
            }
        case ActionType.GET_DOMAIN_ERRORS_BEGIN:
            return {
                ...state,
                areErrorsLoading: true
            }
        case ActionType.GET_DOMAIN_ERRORS_SUCCESS:
            return {
                ...state,
                domainErrorsData: action.payload,
                areErrorsLoading: false
            }
            
        case ActionType.GET_DOMAIN_ERRORS_ERROR:
            return {
                ...state,
                areErrorsLoading: false
            }
        default:
            return state;
    }
}

import React, { Suspense } from 'react';
import { Route } from 'react-router-dom'
import { Layout } from 'antd';
import SiteHeader from '../components/header';
import ProfileMenu from '../components/profile/ProfileMenu';
import Can from '../services/permisions/Can'

const { Content } = Layout;

/* Profile Layout */
const ProfileLayout = ({ children, ...rest }) => {

    return (
        <Layout>
            <SiteHeader></SiteHeader>
            <Layout>
                <Layout>
                    <Content>
                        <div className="settings-block tb--pb-8">
                            <ProfileMenu />
                            <div className="settings--section-content">
                                {children}
                            </div>
                        </div>
                    </Content>
                </Layout>
            </Layout>
        </Layout>
    )
}

/*
  Private route wrapper
 */

export const ProfileLayoutRoute = ({ component: Component, ...rest }) => {
    return (
        <Route {...rest} render={matchProps => (
            <Can I={rest.permissions.type} a={rest.permissions.name} withRedirect={true} >
                <ProfileLayout>
                    <Suspense fallback={<></>}>
                        <Component {...matchProps} />
                    </Suspense>
                </ProfileLayout>
            </Can>
        )} />
    )
};


export const SECTIONS = {
    mainMenu: 'MainMenu',
    secondaryMenu: 'SecondaryMenu',
    footerMenu: 'FooterMenu',
    mobileNavBar: 'MobileNavBar',
    floatingMenu: 'FloatingMenu',
    desktopNavBarMenu: 'DesktopNavBarMenu',
    registrationForm: 'registrationForm',
    loginForm: 'loginForm',
    forgotPasswordForm: 'forgotPasswordForm',
    forgotUserNameForm: 'forgotUserNameForm',
    recoverPasswordForm: 'recoverPasswordForm',
    changePasswordForm: 'changePasswordForm',
    account: 'account',
    rules: 'Rules',
    accountMenu: 'AccountMenu',
    mobileAccountMenu: 'MobileRightMenu',
    featuredMenu: 'FeaturedMenu',

}

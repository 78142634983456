import { ActionType } from '../../constants/ActionTypes';


export default function backgroundImagesReducer(state = {}, action = {}) {
    switch (action.type) {
        case ActionType.GET_BACKGROUND_IMAGES_BEGIN:
            return {
                ...state,
                loading: true,
                saving: false,
                data: []
            }
        case ActionType.GET_BACKGROUND_IMAGES_ERROR:
            return {
                ...state,
                loading: false
            }
        case ActionType.GET_BACKGROUND_IMAGES_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload
            }

        case ActionType.GET_BACKGROUND_IMAGE_PAGES_BEGIN:
            return {
                ...state,
                pagesLoading: true,
                saving: false,
                pages: []
            }
        case ActionType.GET_BACKGROUND_IMAGE_PAGES_ERROR:
            return {
                ...state,
                pagesLoading: false
            }
        case ActionType.GET_BACKGROUND_IMAGE_PAGES_SUCCESS:
            return {
                ...state,
                pagesLoading: false,
                pages: action.payload
            }

        case ActionType.ADD_BACKGROUND_IMAGE_BEGIN:
            return {
                ...state,
                saving: true
            }
        case ActionType.ADD_BACKGROUND_IMAGE_ERROR:
        case ActionType.ADD_BACKGROUND_IMAGE_SUCCESS:
            return {
                ...state,
                saving: false
            }
        case ActionType.EDIT_BACKGROUND_IMAGE_BEGIN:
            return {
                ...state,
                saving: true,
            }
        case ActionType.EDIT_BACKGROUND_IMAGE_ERROR:
        case ActionType.EDIT_BACKGROUND_IMAGE_SUCCESS:
            return {
                ...state,
                saving: false,
            }

        case ActionType.DELETE_BACKGROUND_IMAGE_BEGIN:
            return {
                ...state,
                saving: false,
                deleting: true
            }
        case ActionType.DELETE_BACKGROUND_IMAGE_ERROR:
        case ActionType.DELETE_BACKGROUND_IMAGE_SUCCESS:
            return {
                ...state,
                deleting: false
            }
        default:
            return state;
    }
}

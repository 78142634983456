/**
 * @namespace Browser 
 */

export const Browser = {

    /** 
     * @typedef WindowDimensions
     * @property {Number} heigth Is user loged in or not
     * @property {Number} width Token
     */

    /** Get Browser window dimensions
     * @returns {WindowDimensions} Height and width browser window
     */
    getWindowDimensions() {
        const w = window,
            d = document,
            e = d.documentElement,
            g = d.getElementsByTagName('body')[0],
            x = w.innerWidth || e.clientWidth || g.clientWidth,
            y = w.innerHeight || e.clientHeight || g.clientHeight;
        return { heigth: y, width: x };
    },

    /** Detects from user agent string is it crawler bot or not
     * @link https://udger.com/resources/ua-list/crawlers
     * @param {string } userAgent User uganet string
     * @returns {bool} True if user agent string belongs to crawler bot
     */
    isCrawelerBot(userAgent) {
        const botPattern = `/bot|googlebot|yandex|bing|yahoo|teoma|slurp|duckduckbot|baidu|crawler|spider|robot|crawling/`;
        let regexp = new RegExp(botPattern, 'i');
        return regexp.test(userAgent);
    },

    /** Detects user agent string
     * @returns {string} Returns user ugent string
     */
    getUserAgentString() {
        return navigator.userAgent;
    },
    /** Detects domain of the site without subdomains
      * @returns {string} Returns user current domain
      */
    getDomain(subDomainIncluded = true, excludeWWW = true) {
        let currentDomain = ""
        if (subDomainIncluded) {
            currentDomain = window.location.host
        } else {

            let separate = window.location.host.split(".");

            if (separate.length > 2) {
                separate = separate.slice(separate.length - 2, separate.length);
                currentDomain = separate.join('.');
            }
        }

        currentDomain = currentDomain.toLowerCase();

        if(excludeWWW && currentDomain.startsWith('www.')){
            currentDomain = currentDomain.substring(4)
        }

        return currentDomain;
    },

    getProtocol() {
        return window.location.protocol;
    },


    /**
     * Get url param value by name.
     * @param {string } paramName User uganet string
     * @returns {string} value of parameter
     */
    getURLParamValue(paramName) {
        var urlString = window.location.href
        var url = new URL(urlString);
        var value = url.searchParams.get(paramName);
        return value;
    }

}

import { ActionType } from '../../../constants/ActionTypes';


export default function businessRulesReducer(state = {}, action = {}) {
    switch (action.type) {
        case ActionType.GET_BUSINESS_RULES_LIST_BEGIN:
            return {
                ...state,
                loading: true,
                data: []
            }
        case ActionType.GET_BUSINESS_RULES_LIST_ERROR:
            return {
                ...state,
                loading: false
            }
        case ActionType.GET_BUSINESS_RULES_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload
            }
        case ActionType.GET_BUSINESS_RULE_BEGIN:
            return {
                ...state,
                isRuleLoading: true,
                currentRule: {}
            }
        case ActionType.GET_BUSINESS_RULE_ERROR:
            return {
                ...state,
                isRuleLoading: false
            }
        case ActionType.GET_BUSINESS_RULE_SUCCESS:
            return {
                ...state,
                isRuleLoading: false,
                currentRule: action.payload,
                rule:action.payload
            }

        case ActionType.GET_BUSINESS_RULE_TRIGGERS_BEGIN:
            return {
                ...state,
                isTriggerLoading: true,
                triggers: {}
            }
        case ActionType.GET_BUSINESS_RULE_TRIGGERS_ERROR:
            return {
                ...state,
                isTriggerLoading: false
            }
        case ActionType.GET_BUSINESS_RULE_TRIGGERS_SUCCESS:
            return {
                ...state,
                isTriggerLoading: false,
                triggers: action.payload
            }
        case ActionType.GET_BUSINESS_RULE_POPUPS_BEGIN:
            return {
                ...state,
                isPopupsLoading: true,
                popups: {}
            }
        case ActionType.GET_BUSINESS_RULE_POPUPS_ERROR:
            return {
                ...state,
                isPopupsLoading: false,
            }
        case ActionType.GET_BUSINESS_RULE_POPUPS_SUCCESS:
            return {
                ...state,
                isPopupsLoading: true,
                popups: action.payload
            }
        case ActionType.ADD_BUSINESS_RULE_BEGIN:
            return {
                ...state,
                saving: true
            }
        case ActionType.ADD_BUSINESS_RULE_ERROR:
        case ActionType.ADD_BUSINESS_RULE_SUCCESS:
            return {
                ...state,
                saving: false
            }
        case ActionType.EDIT_BUSINESS_RULE_BEGIN:
            return {
                ...state,
                isRuleLoading: true,
            }
        case ActionType.EDIT_BUSINESS_RULE_ERROR:
        case ActionType.EDIT_BUSINESS_RULE_SUCCESS:
            return {
                ...state,
                isRuleLoading: false,
            }

        case ActionType.DELETE_BUSINESS_RULE_BEGIN:
            return {
                ...state,
                editing: true
            }
        case ActionType.DELETE_BUSINESS_RULE_ERROR:
        case ActionType.DELETE_BUSINESS_RULE_SUCCESS:
            return {
                ...state,
                editing: false
            }
        case ActionType.SET_CURRENT_BUSINESS_RULE:
            return {
                ...state,
                currentRule: action.payload
            }
            case ActionType.GET_POPUP_RULES_BEGIN:
                return {
                    ...state,
                    popupRules: [],
                    popupRulesLoading:true
                }
            case ActionType.GET_POPUP_RULES_ERROR:
                return {
                    ...state,
                    popupRulesLoading:false
                }
            case ActionType.GET_POPUP_RULES_SUCCESS:
            return {
                    ...state,
                    popupRulesLoading: false,
                    popupRules: action.payload
                }
        default:
            return state;
    }
}

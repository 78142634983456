import { ActionType } from '../../../constants/ActionTypes';
export default function paymentsReducer(state = {}, action = {}) {
    switch (action.type) {
        case ActionType.GET_PAYMENTS_BEGIN:
            return {
                ...state,
                error: null,
                data: [],
                isLoading: true,
                isSaving: false
            }
        case ActionType.GET_PAYMENTS_ERROR:
            return {
                ...state,
                isLoading: false,
                error: action.payload
            }
        case ActionType.GET_PAYMENTS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                data: action.payload
            }
        case ActionType.SHOW_ON_WEB_BEGIN:
            return {
                ...state,
                isSaving: true
            }
        case ActionType.SHOW_ON_WEB_ERROR:
            return {
                ...state,
                isSaving: false
            }
        case ActionType.SHOW_ON_WEB_SUCCESS:
            const new_state = state.data.map(item => item.id === action.payload.paymentId ? {...item, showOnWeb : !item.showOnWeb} : item );
            return {
                ...state,
                data: new_state,
                isSaving: false
            }
        default:
            return state;
    }
}

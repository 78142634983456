import ActionType from '../../constants/ActionTypes';


export default function timerReducer(state = {}, action = {}) {

    switch (action.type) {

        case ActionType.GET_TIMER_CONTROL_BEGIN:
        case ActionType.EDIT_TIMER_CONTROL_BEGIN:
            return {
                ...state,
                isLoading: true,
            }
        case ActionType.GET_TIMER_CONTROL_ERROR:
        case ActionType.EDIT_TIMER_CONTROL_ERROR:
            return {
                ...state,
                isLoading: false,
            }
        case ActionType.GET_TIMER_CONTROL_SUCCESS:
        case ActionType.EDIT_TIMER_CONTROL_SUCCESS:
            return {
                ...state,
                ...action.payload,
                isLoading: false,

            }
        default:
            return state;
    }
}
import axios from 'axios';
import { message } from 'antd';
import ActionType from '../../constants/ActionTypes';
import { RestPath } from '../../constants/SitePaths';
import Constants from '../../constants/Constants';
import config from '../../constants/Configs';

export const RequestAction = {
    Approve: 1,
    Reject: 2,
    Cancel: 3,
};

export const RequestStatus = {
    None: 0,
    Pending: 1,
    Approved: 2,
    Declined: 3,
    Canceled: 4,
};

export const RequestActionType = {
    CreatedUser: 1,
    ModifiedCompany: 2,
    ModifiedRole: 3,
    ModifiedProjects: 4,
    ModifiedName: 5,
    ModifiedExpirationDate: 6,
    ModifiedActivityStatus: 7,
};

export function handleRequest(data) {
    return dispatch => {
        dispatch(handleRequestBegin());
        return axios({
            url: config.BASE_URL + RestPath.HANDLE_REQUEST,
            method: Constants.POST,
            data
        })
            .then(response => {
                dispatch(handleRequestSuccess(response.data));
                message.success('Request handled successfully.');
                return true;
            })
            .catch(error => {
                dispatch(handleRequestError(error.response && error.response.data));
                message.error('Failed to handle the request.');
                return false;
            });
    };
}

export const handleRequestBegin = () => ({
    type: ActionType.HANDLE_REQUEST_BEGIN,
});

export const handleRequestSuccess = data => ({
    type: ActionType.HANDLE_REQUEST_SUCCESS,
    payload: data,
});

export const handleRequestError = error => ({
    type: ActionType.HANDLE_REQUEST_ERROR,
    payload: error,
});

// Get requests with filtering and pagination
export function getRequests(params) {
    return dispatch => {
        dispatch(getRequestsBegin());
        return axios({
            url: config.BASE_URL + RestPath.GET_REQUESTS,
            method: Constants.GET,
            params
        })
            .then(response => {
                dispatch(getRequestsSuccess(response.data));
                return response.data;
            })
            .catch(error => {
                dispatch(getRequestsError(error.response && error.response.data));
                message.error('Failed to fetch requests.');
                return null;
            });
    };
}

export const getRequestsBegin = () => ({
    type: ActionType.GET_REQUESTS_BEGIN,
});

export const getRequestsSuccess = data => ({
    type: ActionType.GET_REQUESTS_SUCCESS,
    payload: data,
});

export const getRequestsError = error => ({
    type: ActionType.GET_REQUESTS_ERROR,
    payload: error,
});

// Get requests with filtering and pagination
export function getRequestsCount() {
    return dispatch => {
        return axios({
            url: config.BASE_URL + RestPath.GET_REQUESTS_COUNT,
            method: Constants.GET
        })
            .then(response => {
                dispatch(getRequestsCountSuccess(response.data));
                return response.data;
            })
            .catch(error => {
                return null;
            });
    };
}


export const getRequestsCountSuccess = data => ({
    type: ActionType.GET_REQUESTS_COUNT_SUCCESS,
    payload: data,
});

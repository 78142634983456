import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { injectIntl } from 'react-intl';
import { useHistory, useLocation } from 'react-router-dom';
import { RoutePath } from '../../constants/SitePaths';
import Can from '../../services/permisions/Can';
import { Menu } from 'antd';
import { getRequestsCount } from '../../actions/account/RequestManagementActions';

const ProfileMenu = ({ intl }) => {
    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();
    const { requestsCount } = useSelector(state => state.builder.account.requests);

    useEffect(() => {
        if (Can({
            I: "view",
            a: "userManagement.userRequests",
            fallback: false,
            children: true
        })) {
            dispatch(getRequestsCount());
        }
    }, []);

    const redirectToProfile = (url) => {
        history.push(url);
    };


    const profileSections = [
        { id: 1, icon: 'profile-user-bicon', name: "userMyProfile", size: 'fi', url: RoutePath.PROFILE, title: intl.messages.myProfile },
        { id: 2, icon: 'role-bicon', name: "userRoles", size: 'fi', url: RoutePath.ROLES, title: intl.messages.roles },
        { id: 3, icon: 'bank-bicon', name: "SuperAdmin", size: 'fi', url: RoutePath.COMPANIES, title: intl.messages.companies },
        { id: 4, icon: 'globe-bicon', name: "userUsers", size: 'fi', url: RoutePath.USERS, title: intl.messages.users },
        { id: 5, icon: 'checked-bicon', name: "userRequests", size: 'fi', url: RoutePath.REQUESTS, title: intl.messages.requests }
    ];

    let selectedKeys = [];
    const profileMenu = profileSections.filter(item => {
        return !item.name || Can({
            I: "view",
            a: item.name !== 'SuperAdmin' ? `userManagement.${item.name}` : item.name,
            fallback: false,
            children: true
        });
    }).map(menuItem => {
        if (location.pathname === menuItem.url || location.pathname.match(`^${menuItem.url}`)) {
            selectedKeys = [menuItem.id + ''];
        }
        return (
            <Menu.Item key={menuItem.id} onClick={() => redirectToProfile(menuItem.url)} className="tb--flex tb--align-center">
                <i className={`${menuItem.size} ${menuItem.icon}`}></i>
                <span className="tb--mh-10">
                    {menuItem.title}
                </span>
                {menuItem.name === 'userRequests' && requestsCount > 0 && <span className="requests-count">{requestsCount}</span>}
            </Menu.Item>
        );
    });

    return (
        <div className="sidenav">
            <Menu
                className='tb--pt-10'
                defaultOpenKeys={['1']}
                selectedKeys={selectedKeys}
                mode="inline"
            >
                {profileMenu}
            </Menu>
        </div>
    );
};

export default injectIntl(ProfileMenu);

import {ActionType} from '../../../constants/ActionTypes'
export default function languagesReducer(state = {}, action = {}) {
    switch (action.type) {
        case ActionType.GET_PROJECS_LANGUAGES_SUCCESS:
            return {
                ...state,
                inLoadingProcess: false,
                data: action.payload
            }
        case ActionType.GET_PROJECS_LANGUAGES_ERROR:
            return {
                ...state,
                inLoadingProcess: false
            }
        case ActionType.SET_PROJECTS_LANGUAGES_ERROR:
            return {
                ...state,
                error: action.payload,
                inSavingProcess: false
            }
        case ActionType.SET_PROJECTS_LANGUAGES_SUCCESS:
            return {
                ...state,
                inSavingProcess: false,
                data: action.payload
            }
        case ActionType.GET_LANGUAGES_BEGIN:
            return {
                ...state,
                inLoadingProcess: true,
                inSavingProcess: false,
                data: []
            }
        case ActionType.SET_LANGUAGES_BEGIN:
            return {
                ...state,
                inSavingProcess: true
            }
        default:
            return state;
    }
}

import axios from 'axios';
import { RestPath } from '../../constants/SitePaths'
import config from '../../constants/Configs';
import Constants from '../../constants/Constants';

/**
 * @namespace Screenshot 
 */

export const Screenshot = {

    /**
     * Takes screenshot of the web site home page by project id.
     * @param {Number} projectId  Project id of web site
     * @param {Number} companyId  Company id of the project
     * @returns {string} value of parameter
     */
    take(projectId /*, companyId*/) {
        //if parameters are not provided do not try to take a screenshot
        if (!projectId /*&& !companyId*/) {
            return;
        }
        return axios({
            url: `${config.BASE_URL}${RestPath.SCREENSHOT_TAKE}?projectId=${projectId}`,
            method: Constants.POST
        })
            .then(response => {
                console.log("Screenshot has been successfully taken" )
                return response;
            })
            .catch((error) => {
                console.log("Problem with takeing web site screenshot" )
            })
    }

}

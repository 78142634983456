import ActionType from '../../constants/ActionTypes';
//UI settings reducer
export default function uiControlsReducer(state = {}, action = {}) {

    switch (action.type) {
        case ActionType.GET_NOT_CLASSIFIED_ITEMS_BEGIN:
        case ActionType.EDIT_UI_CONTROL_BEGIN:
        case ActionType.CHANGE_UI_CONTROL_VISIBILITY_BEGIN:
            return {
                ...state,
                inProcess: true
            }
        case ActionType.GET_NOT_CLASSIFIED_ITEMS_ERROR:
        case ActionType.EDIT_UI_CONTROL_ERROR:
            return {
                ...state,
                itemsError: action.payload,
                inProcess: false
            }
        case ActionType.GET_NOT_CLASSIFIED_ITEMS_SUCCESS:
        case ActionType.CHANGE_UI_CONTROL_VISIBILITY_SUCCESS:
            return {
                ...state,
                uiControls: action.payload,
                inProcess: false
            }
        case ActionType.EDIT_UI_CONTROL_SUCCESS:
            return {
                ...state,
                inProcess: false
            }
        case ActionType.CHANGE_UI_CONTROL_VISIBILITY_ERROR:
            return {
                ...state,
                uiControlError: action.payload,
                inProcess: false
            }
        default:
            return state;
    }
}


// Import libraries
import React, { useEffect, useState } from 'react';
import { Route, Redirect, Switch, useHistory } from 'react-router-dom'

//Import site layouts 
import { LoginLayoutRoute } from './layouts/LoginLayout';
import { MainLayoutRoute } from './layouts/MainLayout';
import { EmptyLayoutRoute } from './layouts/EmptyLayout';
import { ProfileLayoutRoute } from './layouts/ProfileLayout';
import { SettingsLayoutRoute } from './layouts/SettingsLayout';
import { UICustomizationLayoutRoute } from './layouts/UICustomizationLayout';
import { RoutePath } from './constants/SitePaths';
import NotFound from './routes/NotFound';

// Import drag and drop css
import '../node_modules/dragula/dist/dragula.css'

//import Payments from './routes/settings/payments/Payments';
import { useDispatch, useSelector } from 'react-redux';
import { Auth } from './services/Auth';
import { Utils } from './services/Utils';
import { getUserData } from './actions/UserActions';
import { LanguageService } from "./services/LanguageService";

//Account 
import config from './constants/Configs'

//Import site routs 
const Site = React.lazy(() => import('./routes/project/Site'))
const Sso = React.lazy(() => import('./routes/Sso'))
const Projects = React.lazy(() => import('./routes/project/Projects'))
const CreatePage = React.lazy(() => import('./routes/CreatePage'))
const Overview = React.lazy(() => import('./routes/settings/Overview'))
const CreateProject = React.lazy(() => import('./routes/project/CreateProject'))

//Import settings routes
const Captcha = React.lazy(() => import('./routes/settings/Captcha'))
const Currencies = React.lazy(() => import('./routes/settings/Currencies'))
const Languages = React.lazy(() => import('./routes/settings/Languages'))
const Customize = React.lazy(() => import('./routes/ui/Customize'))
const AgeRestriction = React.lazy(() => import('./routes/settings/general-settings/AgeRestriction'))
const Licenses = React.lazy(() => import('./routes/settings/general-settings/Licenses'))
const Affiliate = React.lazy(() => import("./routes/settings/general-settings/Affiliate"))
const ReferralFriend = React.lazy(() => import("./routes/settings/general-settings/ReferralFriend"))
const Products = React.lazy(() => import('./routes/settings/Products'))
const Casino = React.lazy(() => import('./routes/settings/Casino'))
const ThirdPartyIntegrations = React.lazy(() => import('./routes/settings/ThirdPartyIntegrations'));
const CustomScripts = React.lazy(() => import('./routes/settings/custom-scripts/CustomScripts'));
const AddOns = React.lazy(() => import('./routes/settings/AddOns'));
const SeoSetiings = React.lazy(() => import('./routes/settings/seo/SeoSettings'));
const SeoSocial = React.lazy(() => import('./routes/settings/seo/seoSocial/SeoSocial'))
const SeoSchema = React.lazy(() => import('./routes/settings/seo/SeoSchema'))
const SeoSitemap = React.lazy(() => import('./routes/settings/seo/SeoSitemap'))
const Domains = React.lazy(() => import('./routes/settings/domains/Domains'))
const AddDomain = React.lazy(() => import('./routes/settings/domains/AddDomain'))
const DomainInfo = React.lazy(() => import('./routes/settings/domains/DomainInfo'))
const DevTools = React.lazy(() => import('./routes/settings/DevTools'))

//Account
const Login = React.lazy(() => import('./routes/account/Login'))
const Registration = React.lazy(() => import('./routes/account/Registration')) // ?
const AccountVerification = React.lazy(() => import('./routes/account/verification'))
const ForgotPassword = React.lazy(() => import('./routes/account/ForgotPassword'))
const Profile = React.lazy(() => import('./components/profile/Profile'))
const Roles = React.lazy(() => import('./components/profile/roles/Roles'))
const AddRole = React.lazy(() => import('./components/profile/roles/AddRole'))
const EditRole = React.lazy(() => import('./components/profile/roles/EditRole'))
const VIewRole = React.lazy(() => import('./components/profile/roles/ViewRole'))
const Users = React.lazy(() => import('./components/profile/users/Users'))
const Requests = React.lazy(() => import('./components/profile/requests/Requests'))
const AddEditUser = React.lazy(() => import('./components/profile/users/AddEditUser'))
const UserDetails = React.lazy(() => import('./components/profile/users/UserDetails'))
const SetPassword = React.lazy(() => import('./routes/account/SetPassword'))
const Companies = React.lazy(() => import('./components/profile/companies/Companies'))

/** The main class of DigiBuilder, where discribed the routing of pages*/
function App() {
  const dispatch = useDispatch();
  const history = useHistory();
  const [isLoading, setLoading] = useState(true);
  const user = useSelector(state => state.user);
  useEffect(() => {
    if (Auth?.isAuthenticated() && history.location.pathname !== RoutePath.SSO) {
      dispatch(getUserData()).then(() => setLoading(false))
    } else {
      setLoading(false)
    }
    checkLanguage();
  }, [])


  function checkLanguage() {
    const { language } = Utils.parseQuery(history.location.search)
    LanguageService.changeLanguage(dispatch, language);
  }
  return (
    isLoading ? <></> :
      <Switch>
        <Route exact path={RoutePath.DEFAULT}>
          <Redirect to={Auth?.isAuthenticated() ? RoutePath.PROJECTS : RoutePath.LOGIN} />
        </Route>

        <LoginLayoutRoute path={RoutePath.LOGIN} component={Login} />
        <LoginLayoutRoute path={`${RoutePath.SET_PASSWORD}/:hash`} component={SetPassword} />
        {config.IS_REGISTRATION_ENABLED && <LoginLayoutRoute path={RoutePath.REGISTRATION} component={Registration} />}
        <LoginLayoutRoute path={RoutePath.ACCOUNT_VERIFICATION} component={AccountVerification} />
        <LoginLayoutRoute path={RoutePath.FORGOT_PASSWORD} component={ForgotPassword} />

        {/*Main Layout */}
        <MainLayoutRoute exact path={RoutePath.SITE_BUILDER} component={Site} permissions={{ name: 'general.generalProject', type: 'add' }} />
        <MainLayoutRoute exact path={RoutePath.CREATE_PROJECT} component={CreateProject} permissions={{ name: 'general.generalProject', type: 'add' }} />
        <MainLayoutRoute exact path={RoutePath.PROJECTS} component={Projects} />
        <MainLayoutRoute exact path={RoutePath.CREATE_PAGE} component={CreatePage} />

        {/*Profile Layout */}
        <ProfileLayoutRoute exact path={RoutePath.PROFILE} component={Profile} permissions={{ name: 'userManagement.userMyProfile', type: 'view' }} />
        <ProfileLayoutRoute exact path={RoutePath.ROLES} component={Roles} permissions={{ name: 'userManagement.userRoles', type: 'view' }} />
        <ProfileLayoutRoute exact path={RoutePath.ADD_ROLES} component={AddRole} permissions={{ name: 'userManagement.userRoles', type: 'add' }} />
        <ProfileLayoutRoute exact path={`${RoutePath.EDIT_ROLE}/:id`} component={EditRole} permissions={{ name: 'userManagement.userRoles', type: 'edit' }} />
        <ProfileLayoutRoute exact path={`${RoutePath.VIEW_ROLE}/:id`} component={VIewRole} permissions={{ name: 'userManagement.userRoles', type: 'view' }} />
        <ProfileLayoutRoute exact path={RoutePath.USERS} component={Users} permissions={{ name: 'userManagement.userUsers', type: 'view' }} />
        <ProfileLayoutRoute exact path={RoutePath.ADD_USER} component={AddEditUser} permissions={{ name: 'userManagement.userUsers', type: 'add' }} />
        <ProfileLayoutRoute exact path={`${RoutePath.USER_DETAILS}/:id`} component={UserDetails} permissions={{ name: 'userManagement.userUsers', type: 'view' }} />
        <ProfileLayoutRoute exact path={`${RoutePath.EDIT_USER}/:id`} component={AddEditUser} permissions={{ name: 'userManagement.userUsers', type: 'edit' }} />
        <ProfileLayoutRoute exact path={RoutePath.COMPANIES} component={Companies} permissions={{ name: 'SuperAdmin', type: 'view', withRedirect: true }} />
        <ProfileLayoutRoute exact path={RoutePath.REQUESTS} component={Requests} permissions={{ name: 'userManagement.userUsers', type: 'view' }} />
        {/*Settings Layout */}
        <SettingsLayoutRoute exact path={`${RoutePath.SETTINGS_OVERVIEW}/:id`} component={Overview} permissions={{ name: 'page.projectSettings', type: 'view' }} />
        <SettingsLayoutRoute exact path={`${RoutePath.SETTINGS_LANGUAGES}/:id`} component={Languages} permissions={{ name: 'projectSettings.settingsLanguages', type: 'view' }} />
        <SettingsLayoutRoute exact path={`${RoutePath.SETTINGS_CAPTCHA}/:id`} component={Captcha} permissions={{ name: 'projectSettings.settingsCaptcha', type: 'view' }} />
        <SettingsLayoutRoute exact path={`${RoutePath.SETTINGS_CURRENCIES}/:id`} component={Currencies} permissions={{ name: 'projectSettings.settingsCurrencies', type: 'view' }} />
        <SettingsLayoutRoute exact path={`${RoutePath.SETTINGS_CASINO}/:id`} component={Casino} />
        {/* <SettingsLayoutRoute exact path={`${RoutePath.SETTINGS_PAYMENTS}/:id`} component={Payments} permissions={{ name: 'projectSettings.settingsPayments', type: 'view' }} /> */}
        <SettingsLayoutRoute exact path={`${RoutePath.SETTINGS_AGE_RESTRICTION}/:id`} component={AgeRestriction} permissions={{ name: 'projectSettings.settingsAgeRestriction', type: 'view' }} />
        <SettingsLayoutRoute exact path={`${RoutePath.SETTINGS_LICENSE}/:id`} component={Licenses} permissions={{ name: 'projectSettings.settingsLicense', type: 'view' }} />
        {config.NEW_PLAT && <SettingsLayoutRoute exact path={`${RoutePath.SETTINGS_AFFILIATE}/:id`} component={Affiliate} permissions={{ name: 'projectSettings.settingsAffiliate', type: 'view' }} />}


        <SettingsLayoutRoute exact path={`${RoutePath.SETTINGS_REFERRAL_ID}/:id`} component={ReferralFriend} permissions={{ name: 'projectSettings.settingsReferAFriend', type: 'view' }} />

        <SettingsLayoutRoute exact path={`${RoutePath.SETTINGS_PRODUCTS}/:type/:id`} component={Products} permissions={{ name: 'projectSettings.settingsProducts', type: 'view' }} />


        <SettingsLayoutRoute exact path={`${RoutePath.THIRD_PARTY_INTEGRATIONS}/:id`} component={ThirdPartyIntegrations} permissions={{ name: 'projectSettings.settings3rdparty', type: 'view' }} />
        <SettingsLayoutRoute exact path={`${RoutePath.SETTINGS_SEO}/:id`} component={SeoSetiings} permissions={{ name: 'projectSettings.settingsSEO', type: 'view' }} />
        <SettingsLayoutRoute exact path={`${RoutePath.SETTINGS_SEO_SOCIAL}/:id`} component={SeoSocial} permissions={{ name: 'projectSettings.settingsSEO', type: 'view' }} />
        <SettingsLayoutRoute exact path={`${RoutePath.SETTINGS_SEO_SCHEMA_MARKUP}/:id`} component={SeoSchema} permissions={{ name: 'projectSettings.settingsSEO', type: 'view' }} />
        <SettingsLayoutRoute exact path={`${RoutePath.SETTINGS_SEO_SITEMAP}/:id`} component={SeoSitemap} permissions={{ name: 'projectSettings.settingsSEO', type: 'view' }} />
        <SettingsLayoutRoute exact path={`${RoutePath.ADD_ONS}/:id`} component={AddOns} permissions={{ name: 'projectSettings.settingsAddOns', type: 'view' }} />
        <SettingsLayoutRoute exact path={`${RoutePath.CUSTOM_SCRIPTS}/:type/:id`} component={CustomScripts} permissions={{ name: { script: 'projectSettings.settingsCustomScripts', style: 'projectSettings.settingsCustomStyles' }, type: 'view' }} />
        <SettingsLayoutRoute exact path={`${RoutePath.SETTINGS_DOMAINS}/:id`} component={Domains} permissions={{ name: 'projectSettings.settingsDomains', type: 'view' }} />
        <SettingsLayoutRoute exact path={`${RoutePath.SETTINGS_DOMAINS_INFO}/:id/:ns1?/:ns2?`} component={DomainInfo} permissions={{ name: 'projectSettings.settingsDomains', type: 'view' }} />
        <SettingsLayoutRoute exact path={`${RoutePath.SETTINGS_ADD_DOMAIN}/:id`} component={AddDomain} permissions={{ name: 'projectSettings.settingsDomains', type: 'manage' }} />
        <SettingsLayoutRoute exact path={`${RoutePath.DEV_TOOLS}/:id`} component={DevTools} permissions={{ name: 'projectSettings.settingsAddOns', type: 'view' }} />
        {/* UI Customization Layout */}
        <UICustomizationLayoutRoute exact path={`${RoutePath.CUSTOMIZE}/:id`} component={Customize} permissions={{ name: 'page.uiCustomization', type: 'view' }} />
        <EmptyLayoutRoute exact path={`${RoutePath.SSO}`} component={Sso} />
        <EmptyLayoutRoute component={NotFound} />

      </Switch>
  );
}

export default App;
import React, { Suspense } from 'react';
import { Route } from 'react-router-dom'
import { Layout } from 'antd';
import SiteHeader from '../components/customization/header';
import Can from '../services/permisions/Can';

const { Content } = Layout;

/* Main Layout */
const UICustomizationLayout = ({ children, ...rest }) => {
  return (
    <Layout>
      <Layout>
        <SiteHeader></SiteHeader>
        <Layout>
          <Content>
            {children}
          </Content>
        </Layout>
      </Layout>
    </Layout>
  )
}

/*
  Private route wrapper
 */

export const UICustomizationLayoutRoute = ({ component: Component, ...rest }) => {
  return (
    <Route {...rest} render={matchProps => (
      <Can I={rest.permissions.type} a={rest.permissions.name} withRedirect={true} >
        <UICustomizationLayout>
          <Suspense fallback={<></>}>
            <Component {...matchProps} />
          </Suspense>
        </UICustomizationLayout>
      </Can>
    )} />
  )
};


import ActionType from '../../constants/ActionTypes';

export default function providersReducer(state = {}, action = {}) {
    switch (action.type) {
        case ActionType.GET_PROVIDER_CONTROLS_BEGIN:
        case ActionType.ADD_PROVIDER_CONTROLS_BEGIN:
        case ActionType.CHANGE_PROVIDER_SECTION_VISIBILITY_BEGIN:
        case ActionType.CHANGE_PROVIDER_VISIBILITY_BEGIN:
            return {
                ...state,
                inProcess: true
            }

        case ActionType.GET_PROVIDER_CONTROLS_ERROR:
        case ActionType.ADD_PROVIDER_CONTROLS_ERROR:
        case ActionType.ADD_PROVIDER_CONTROLS_SUCCESS:
            return {
                ...state,
                inProcess: false
            }

        case ActionType.GET_PROVIDER_CONTROLS_SUCCESS:
            return {
                ...state,
                inProcess: false,
                providers: action.payload
            }

        case ActionType.GET_AVAILABLE_PROVIDERS_BEGIN:
            return {
                ...state,
                availableProvidersLoading: true
            }

        case ActionType.GET_AVAILABLE_PROVIDERS_ERROR:
            return {
                ...state,
                availableProvidersLoading: false
            }

        case ActionType.GET_AVAILABLE_PROVIDERS_SUCCESS:
            return {
                ...state,
                availableProvidersLoading: false,
                availableProviders: action.payload
            }
        case ActionType.DELETE_PROVIDER_CONTROLS_BEGIN:
            return {
                ...state,
                inDeleteProcess: true
            }

        case ActionType.DELETE_PROVIDER_CONTROLS_ERROR:
            return {
                ...state,
                inDeleteProcess: false
            }

        case ActionType.DELETE_PROVIDER_CONTROLS_SUCCESS:
            return {
                ...state,
                inDeleteProcess: false,

            }
        case ActionType.EDIT_PROVIDER_CONTROL_BEGIN:
            return {
                ...state,
                inProcess: true,
            }

        case ActionType.EDIT_PROVIDER_CONTROL_ERROR:
            return {
                ...state,
                providersError: action.payload,
                inProcess: false,
            }

        case ActionType.EDIT_PROVIDER_CONTROL_SUCCESS:
            return {
                ...state,
                providers: action.payload,
                inProcess: false,
            }
        case ActionType.CHANGE_PROVIDER_SECTION_VISIBILITY_ERROR:
        case ActionType.CHANGE_PROVIDER_VISIBILITY_ERROR:
            return {
                ...state,
                providersError: action.payload,
                inProcess: false
            }

        case ActionType.CHANGE_PROVIDER_SECTION_VISIBILITY_SUCCESS:
        case ActionType.CHANGE_PROVIDER_VISIBILITY_SUCCESS:
            return {
                ...state,
                providers: action.payload,
                inProcess: false
            }
        case ActionType.SET_PROVIDER_LOGO_SETTINGS_BEGIN:
            return {
                ...state,
                isSaving: true
            }
        case ActionType.SET_PROVIDER_LOGO_SETTINGS_ERROR:
        case ActionType.SET_PROVIDER_LOGO_SETTINGS_SUCCESS:
            return {
                ...state,
                isSaving: false
            }
        case ActionType.GET_PROVIDER_LOGO_SETTINGS_BEGIN:
            return {
                ...state,
                isSaving: false,
                isLoading: true
            }
        case ActionType.GET_PROVIDER_LOGO_SETTINGS_ERROR:
            return {
                ...state,
                isLoading: false
            }
        case ActionType.GET_PROVIDER_LOGO_SETTINGS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                providerSettings: action.payload
            }
        default:
            return state;
    }
}
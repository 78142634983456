import { ActionType } from '../../../constants/ActionTypes'
export default function licensesReducer(state = {}, action = {}) {
    switch (action.type) {
        case ActionType.GET_PROJECT_ALL_LICENSES_SUCCESS:
            return {
                ...state,
                allLicenses: action.payload,
                inLoadingProcess: false
            }
        case ActionType.GET_PROJECT_ALL_LICENSES_ERROR:
            return {
                ...state,
                inLoadingProcess: false
            }
        case ActionType.GET_PROJECT_ALL_LICENSES_BEGIN:
            return {
                ...state,
                inLoadingProcess: true
            }
        case ActionType.GET_LICENSE_SETTINGS_SUCCESS:
            return {
                ...state,
                licenseSettings: action.payload,
                inProcess: false,
            }
        case ActionType.GET_LICENSE_SETTINGS_ERROR:
            return {
                ...state,
                inProcess: false,
            }
        case ActionType.GET_LICENSE_SETTINGS_BEGIN:
        case ActionType.ADD_LICENSE_BEGIN:
        case ActionType.DELETE_LICENSE_BEGIN:
            return {
                ...state,
                inProcess: true
            }
        case ActionType.ADD_LICENSE_ERROR:
        case ActionType.ADD_LICENSE_SUCCESS:
        case ActionType.DELETE_LICENSE_ERROR:
            return {
                ...state,
                inProcess: false
            }
        case ActionType.DELETE_LICENSE_SUCCESS:
            return {
                ...state,
                licenseSettings: {},
                inProcess: false
            }
        default:
            return state;
    }
}

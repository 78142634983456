import { ActionType } from '../../constants/ActionTypes';


export default function requestsReducer(state = {}, action = {}) {
    switch (action.type) {
        case ActionType.HANDLE_REQUEST_BEGIN:
            return {
                ...state,
                loading: true
            }
        case ActionType.HANDLE_REQUEST_ERROR:
            return {
                ...state,
                loading: false
            }
        case ActionType.HANDLE_REQUEST_SUCCESS:
            return {
                ...state,
                loading: false
            }
        case ActionType.GET_REQUESTS_BEGIN:
            return {
                ...state,
                isRequestLoading: true,
                data: []
            }
        case ActionType.GET_REQUESTS_ERROR:
            return {
                ...state,
                isRequestLoading: false
            }
        case ActionType.GET_REQUESTS_SUCCESS:
            return {
                ...state,
                isRequestLoading: false,
                data: action.payload
            }
        case ActionType.GET_REQUESTS_COUNT_SUCCESS:
            return {
                ...state,
                requestsCount: action.payload
            }


        default:
            return state;
    }
}

import ActionType from '../../constants/ActionTypes';
//UI settings reducer
export default function featuredProductsReducer(state = {}, action = {}) {

    switch (action.type) {
        case ActionType.GET_FEATURED_PRODUCTS_SUCCESS:
            return {
                ...state,
                inProcess: false,
                featuredProductsData: action.payload
            }
        case ActionType.GET_FEATURED_PRODUCTS_BEGIN:
        case ActionType.ADD_FEATURED_PRODUCTS_BEGIN:
        case ActionType.MOVE_FEATURED_PRODUCTS_BEGIN:
        case ActionType.DELETE_FEATURED_PRODUCTS_BEGIN:
        case ActionType.CHANGE_FEATURED_PRODUCTS_VISIBILITY_BEGIN:
            return {
                ...state,
                inProcess: true
            }
        case ActionType.ADD_FEATURED_PRODUCTS_ERROR:
        case ActionType.MOVE_FEATURED_PRODUCTS_ERROR:
        case ActionType.DELETE_FEATURED_PRODUCTS_ERROR:
        case ActionType.CHANGE_FEATURED_PRODUCTS_VISIBILITY_ERROR:
        case ActionType.GET_FEATURED_PRODUCTS_ERROR:
        case ActionType.ADD_FEATURED_PRODUCTS_SUCCESS:
        case ActionType.MOVE_FEATURED_PRODUCTS_SUCCESS:
        case ActionType.DELETE_FEATURED_PRODUCTS_SUCCESS:
        case ActionType.CHANGE_FEATURED_PRODUCTS_VISIBILITY_SUCCESS:
            return {
                ...state,
                inProcess: false
            }
        case ActionType.EDIT_FEATURED_PRODUCTS_BEGIN:
            return {
                ...state,
                isEditing: true
            }
        case ActionType.EDIT_FEATURED_PRODUCTS_SUCCESS:
        case ActionType.EDIT_FEATURED_PRODUCTS_ERROR:
            return {
                ...state,
                isEditing: false
            }

        default:
            return state;
    }
}

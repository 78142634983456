import { ActionType } from '../../constants/ActionTypes';


export default function SEORulesReducer(state = {}, action = {}) {
    switch (action.type) {
        case ActionType.GET_SEO_RULES_BEGIN:
            return {
                ...state,
                loading: true,
                data: []
            }
        case ActionType.GET_SEO_RULES_ERROR:
            return {
                ...state,
                loading: false
            }
        case ActionType.GET_SEO_RULES_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload
            }

        case ActionType.ADD_SEO_RULE_BEGIN:
            return {
                ...state,
                saving: true
            }
        case ActionType.ADD_SEO_RULE_ERROR:
        case ActionType.ADD_SEO_RULE_SUCCESS:
            return {
                ...state,
                saving: false
            }
        case ActionType.SEO_RULE_STATE_BEGIN:
            return {
                ...state,
                loading: true
            }
        case ActionType.SEO_RULE_STATE_SUCCESS:
        case ActionType.SEO_RULE_STATE_ERROR:
            return {
                ...state,
                loading: false
            }
        case ActionType.ADD_EDIT_SEO_RULE_META_BEGIN:
            return {
                ...state,
                isMetaLoading: true
            }
        case ActionType.ADD_EDIT_SEO_RULE_META_SUCCESS:
        case ActionType.ADD_EDIT_SEO_RULE_META_ERROR:
            return {
                ...state,
                isMetaLoading: false
            }
        case ActionType.SEO_RULES_SECTION_STATE_BEGIN:
            return {
                ...state,
                isSectionStateLoading: true
            }
        case ActionType.SEO_RULES_SECTION_STATE_ERROR:
            return {
                ...state,
                isSectionStateLoading: false
            }
        case ActionType.SEO_RULES_SECTION_STATE_SUCCESS:
            return {
                ...state,
                isSectionStateLoading: false,
                sectionState: action.payload
            }
        case ActionType.GET_SEO_RULE_CONTENTS_BEGIN:
            return {
                ...state,
                isContentLoading: true
            }
        case ActionType.GET_SEO_RULE_CONTENTS_ERROR:
            return {
                ...state,
                isContentLoading: false
            }
        case ActionType.GET_SEO_RULE_CONTENTS_SUCCESS:
            return {
                ...state,
                isContentLoading: false,
                contents: action.payload
            }
        case ActionType.ADD_EDIT_SEO_RULE_BLOCK_CONTENT_BEGIN:
            return {
                ...state,
                isBlockLoading: true
            }
        case ActionType.ADD_EDIT_SEO_RULE_BLOCK_CONTENT_ERROR:
        case ActionType.ADD_EDIT_SEO_RULE_BLOCK_CONTENT_SUCCESS:
            return {
                ...state,
                isBlockLoading: false,
            }
        default:
            return state;
    }
}

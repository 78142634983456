import { ActionType } from '../../constants/ActionTypes';


export default function roleReducer(state = {}, action = {}) {
    switch (action.type) {
        case ActionType.GET_ROLES_LIST_BEGIN:
            return {
                ...state,
                loading: true,
                data: []
            }
        case ActionType.GET_ROLES_LIST_ERROR:
            return {
                ...state,
                loading: false
            }
        case ActionType.GET_ROLES_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload
            }
        case ActionType.GET_ROLE_BEGIN:
            return {
                ...state,
                isRoleLoading: true,
                role: []
            }
        case ActionType.GET_ROLE_ERROR:
            return {
                ...state,
                isRoleLoading: false
            }
        case ActionType.GET_ROLE_SUCCESS:
            return {
                ...state,
                isRoleLoading: false,
                role: action.payload
            }

        case ActionType.GET_ROLE_PERMISSIONS_BEGIN:
            return {
                ...state,
                isTreeLoading: true,
                permissionsTree: {}
            }
        case ActionType.GET_ROLE_PERMISSIONS_ERROR:
            return {
                ...state,
                isTreeLoading: false
            }
        case ActionType.GET_ROLE_PERMISSIONS_SUCCESS:
            return {
                ...state,
                isTreeLoading: false,
                permissionsTree: action.payload
            }
        case ActionType.GET_ROLE_PERMISSIONS_FOR_CHECKING_BEGIN:
            return {
                ...state,
                isTreeForCheckingLoading: true,
                permissionsForChecking: {}
            }
        case ActionType.GET_ROLE_PERMISSIONS_FOR_CHECKING_ERROR:
            return {
                ...state,
                isTreeForCheckingLoading: false
            }
        case ActionType.GET_ROLE_PERMISSIONS_FOR_CHECKING_SUCCESS:
            return {
                ...state,
                isTreeForCheckingLoading: false,
                permissionsForChecking: action.payload,
            }
        case ActionType.ADD_ROLE_BEGIN:
            return {
                ...state,
                saving: true
            }
        case ActionType.ADD_ROLE_ERROR:
        case ActionType.ADD_ROLE_SUCCESS:
            return {
                ...state,
                saving: false
            }
        case ActionType.EDIT_ROLE_BEGIN:
            return {
                ...state,
                isRoleLoading: true,
            }
        case ActionType.EDIT_ROLE_ERROR:
        case ActionType.EDIT_ROLE_SUCCESS:
            return {
                ...state,
                isRoleLoading: false,
            }

        case ActionType.DELETE_ROLE_BEGIN:
            return {
                ...state,
                deleting: true
            }
        case ActionType.DELETE_ROLE_ERROR:
        case ActionType.DELETE_ROLE_SUCCESS:
            return {
                ...state,
                deleting: false
            }

        default:
            return state;
    }
}

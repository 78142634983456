import { ActionType } from '../../../constants/ActionTypes';


export default function devToolsRedusers(state = {}, action = {}) {
    switch (action.type) {
        case ActionType.DEV_TOOLS_ACTION_BEGIN:
            return {
                ...state,
                isLoading: true,
            }
        case ActionType.DEV_TOOLS_ACTION_ERROR:
            return {
                ...state,
                isLoading: false,
            }
        case ActionType.DEV_TOOLS_ACTION_SUCCESS:
            return {
                ...state,
                isLoading: false
            }
        default:
            return state;
    }
}

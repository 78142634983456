import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Redirect, withRouter } from 'react-router-dom';
import { Menu, Popover } from 'antd';
import { logout } from '../../actions/UserActions';
import { Auth } from '../../services/Auth';
import { RoutePath } from '../../constants/SitePaths';
import { injectIntl, FormattedMessage } from 'react-intl'
import Can from '../../services/permisions/Can';
import RolesHelper from '../../services/permisions/RolesHelper';

function AccountMenu({ showName, location, intl }) {

    const user = useSelector(state => state.user);
    const dispatch = useDispatch();


    const menu_sections = {
        dashboard: { url: RoutePath.PROJECTS },
        profile: { url: RoutePath.PROFILE },
        roles: { url: RoutePath.ROLES },
        users: { url: RoutePath.USERS },
        companies: { url: RoutePath.COMPANIES },
        requests: { url: RoutePath.REQUESTS }
    }
    const selectedKeys = Object.keys(menu_sections).filter((item) => (location.pathname.match(`^${menu_sections[item].url}`))).map(item => menu_sections[item].url)
    const menu = (
        <Menu defaultSelectedKeys={[location.pathname]}
            selectedKeys={selectedKeys}
        >
            {!RolesHelper.isAffilateUser() &&
                <Menu.Item key={menu_sections.dashboard.url}><Link to={menu_sections.dashboard.url}><FormattedMessage id="dashboard" /></Link></Menu.Item>
            }
            {Can({
                I: "view",
                a: "userManagement.userMyProfile",
                children: true,
                fallback: false
            }) && <Menu.Item key={menu_sections.profile.url}><Link to={menu_sections.profile.url}><FormattedMessage id="my_profile" /></Link></Menu.Item>}
            {Can({
                I: "view",
                a: "userManagement.userRoles",
                children: true,
                fallback: false
            }) && <Menu.Item key={menu_sections.roles.url}><Link to={menu_sections.roles.url}><FormattedMessage id="roles" /></Link></Menu.Item>}
            {Can({
                I: "view",
                a: "SuperAdmin",
                children: true,
                fallback: false
            }) && <Menu.Item key={menu_sections.companies.url}><Link to={menu_sections.companies.url}><FormattedMessage id="companies" /></Link></Menu.Item>}
            {Can({
                I: "view",
                a: "userManagement.userUsers",
                children: true,
                fallback: false
            }) && <Menu.Item key={menu_sections.users.url}><Link to={menu_sections.users.url}><FormattedMessage id="users" /></Link></Menu.Item>}
            {(Can({
                I: "view",
                a: "userManagement.userRequests",
                children: true,
                fallback: false
            }) || user.superAdmin) && <Menu.Item key={menu_sections.requests.url}><Link to={menu_sections.requests.url}><FormattedMessage id="requests" /></Link></Menu.Item>}

            <Menu.Item ><a onClick={() => {
                dispatch(logout())
            }}><FormattedMessage id="logout" /></a></Menu.Item>
        </Menu >
    );

    if (!user.isLoggedIn && !Auth.isAuthenticated()) {
        return <Redirect to='/login' />;
    }

    return (
        <>
            {!RolesHelper.isAffilateUser() &&
                <div className='tb--flex tb--align-center header-right-actions'>
                    {/* {showName && !isSettingsHeader ? <div className='tb--mh-16'>{this.props.user.firstName} {this.props.user.lastName}</div> : <></>} */}
                    <a href={intl.messages.help_hint} target='_blank' className='cyrcle-outline tb--mr-18'><i className="question-bicon"></i></a>
                    <Popover key={location.pathname} placement="bottom" title={`${user.firstName || ''} ${user.lastName || ''}`} content={menu} trigger={["click"]}>
                        <a className='cyrcle'><i className="user-bicon" /></a>
                    </Popover>
                </div>
            }
        </>
    );
}

export default injectIntl(withRouter(AccountMenu));




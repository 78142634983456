import ActionType from '../../constants/ActionTypes';
//UI settings reducer
export default function paymentControlReducer(state = {}, action = {}) {

    switch (action.type) {

        case ActionType.GET_PAYMENT_CONTROLS_BEGIN:
            return {
                ...state,
                inProcess: true,
                paymentControl: {
                    ...state.paymentControl,
                    inProcess: true,
                }
            }
        case ActionType.GET_PAYMENT_CONTROLS_ERROR:
            return {
                ...state,
                inProcess: false,
                paymentControl: {
                    ...state.paymentControl,
                    inProcess: false
                }
            }
        case ActionType.GET_PAYMENT_CONTROLS_SUCCESS:
            return {
                ...state,
                inProcess: false,
                paymentControl: {
                    ...state.paymentControl,
                    id: action.payload.id,
                    parentId: action.payload.parentId,
                    inProcess: false,
                    isVisible: action.payload.isVisible,
                    nestedControls: action.payload.nestedControls,
                    deviceType: action.payload.deviceType, 
                }

            }

        case ActionType.GET_AVAILABLE_PAYMENT_CONTROLS_BEGIN:
        case ActionType.ADD_PAYMENT_CONTROLS_BEGIN:
        case ActionType.EDIT_PAYMENT_CONTROLS_BEGIN:
        case ActionType.DELETE_PAYMENT_CONTROLS_BEGIN:
            return {
                ...state,
                inProcess: true
            }
        case ActionType.GET_AVAILABLE_PAYMENT_CONTROLS_ERROR:
        case ActionType.ADD_PAYMENT_CONTROLS_ERROR:
        case ActionType.EDIT_PAYMENT_CONTROLS_ERROR:
        case ActionType.DELETE_PAYMENT_CONTROLS_ERROR:
        case ActionType.EDIT_PAYMENT_CONTROLS_SUCCESS:
        case ActionType.DELETE_PAYMENT_CONTROLS_SUCCESS:
            return {
                ...state,
                inProcess: false
            }
        case ActionType.GET_AVAILABLE_PAYMENT_CONTROLS_SUCCESS:
            return {
                ...state,
                inProcess: false,
                availablePayments: action.payload
            }
        case ActionType.CHANGE_PAYMENT_CONTROL_VISIBILITY_BEGIN:
            return {
                ...state,
                inProcess: true,
            }
        case ActionType.CHANGE_PAYMENT_CONTROL_VISIBILITY_ERROR:
            return {
                ...state,
                inProcess: false,
            }
        case ActionType.CHANGE_PAYMENT_CONTROL_VISIBILITY_SUCCESS:
            return {
                ...state,
                paymentControl: {
                    ...state.paymentControl,
                    isVisible: action.payload
                },
                inProcess: false,
            }
        case ActionType.SET_PAYMENT_LOGO_SETTINGS_BEGIN:
            return {
                ...state,
                isSaving: true
            }
        case ActionType.SET_PAYMENT_LOGO_SETTINGS_ERROR:
        case ActionType.SET_PAYMENT_LOGO_SETTINGS_SUCCESS:
            return {
                ...state,
                isSaving: false
            }
        case ActionType.GET_PAYMENT_LOGO_SETTINGS_BEGIN:
            return {
                ...state,
                isSaving: false,
                isLoading: true
            }
        case ActionType.GET_PAYMENT_LOGO_SETTINGS_ERROR:
            return {
                ...state,
                isLoading: false
            }
        case ActionType.GET_PAYMENT_LOGO_SETTINGS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                paymentSettings: action.payload
            }
        case ActionType.ADD_PAYMENT_CONTROLS_SUCCESS:
            const availablePayments = {};
            const deletedPayments = action.payload.deletedItems.paymentIds;

            Object.keys(state.availablePayments).forEach(key => {
                if (!deletedPayments.includes(key)) {
                    availablePayments[[key]] = state.availablePayments[key];
                }
            })

            return {
                ...state,
                inProcess: false,
                availablePayments: availablePayments
            }
        default:
            return state;
    }
}

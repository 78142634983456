import { ActionType } from '../../constants/ActionTypes';


export default function pagesReducer(state = {}, action = {}) {
    switch (action.type) {
        case ActionType.GET_PAGES_BEGIN:
            return {
                ...state,
                loading: true,
                data: []
            }
        case ActionType.GET_PAGES_ERROR:
            return {
                ...state,
                loading: false
            }
        case ActionType.GET_PAGES_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload
            }

        case ActionType.GET_PAGE_CONTENTS_BEGIN:
            return {
                ...state,
                isContentsloading: true,
                pageContents: [],
                pageHistory: []
            }
        case ActionType.GET_PAGE_CONTENTS_ERROR:
            return {
                ...state,
                isContentsloading: false
            }
        case ActionType.GET_PAGE_CONTENTS_SUCCESS:
            return {
                ...state,
                isContentsloading: false,
                pageContents: action.payload
            }
        case ActionType.GET_PAGE_CONTENT_BEGIN:
            return {
                ...state,
                isContentloading: true,
                pageContent: []
            }
        case ActionType.GET_PAGE_CONTENT_AND_RESET_HISTORY_BEGIN:
            return {
                ...state,
                isContentloading: true,
                pageContent: [],
                pageHistory: []
            }
        case ActionType.GET_PAGE_CONTENT_ERROR:
            return {
                ...state,
                isContentloading: false
            }
        case ActionType.GET_PAGE_CONTENT_SUCCESS:
            return {
                ...state,
                isContentloading: false,
                pageContent: action.payload
            }
        case ActionType.GET_PAGE_HISTORY_BEGIN:
            return {
                ...state,
                isHistoryloading: true,
                pageHistory: []
            }
        case ActionType.GET_PAGE_HISTORY_ERROR:
            return {
                ...state,
                isHistoryloading: false
            }
        case ActionType.GET_PAGE_HISTORY_SUCCESS:
            return {
                ...state,
                isHistoryloading: false,
                pageHistory: action.payload
            }
        case ActionType.GET_PAGE_URLS_BEGIN:
            return {
                ...state,
                isUrlsloading: true,
                pageUrls: []
            }
        case ActionType.GET_PAGE_URLS_ERROR:
            return {
                ...state,
                isUrlsloading: false
            }
        case ActionType.GET_PAGE_URLS_SUCCESS:
            return {
                ...state,
                isUrlsloading: false,
                pageUrls: action.payload
            }

        case ActionType.ADD_PAGE_BEGIN:
            return {
                ...state,
                saving: true
            }
        case ActionType.ADD_PAGE_ERROR:
        case ActionType.ADD_PAGE_SUCCESS:
            return {
                ...state,
                saving: false
            }
        case ActionType.EDIT_PAGE_BEGIN:
            return {
                ...state,
                isPageloading: true,
            }
        case ActionType.EDIT_PAGE_ERROR:
        case ActionType.EDIT_PAGE_SUCCESS:
            return {
                ...state,
                isPageloading: false,
            }
        case ActionType.SAVE_PAGE_CONTENT_BEGIN:
            return {
                ...state,
                isContentSaving: true,
            }
        case ActionType.SAVE_PAGE_CONTENT_ERROR:
        case ActionType.SAVE_PAGE_CONTENT_SUCCESS:
            return {
                ...state,
                isContentSaving: false,
            }

        case ActionType.DELETE_PAGE_BEGIN:
            return {
                ...state,
                deleting: true
            }
        case ActionType.DELETE_PAGE_ERROR:
        case ActionType.DELETE_PAGE_SUCCESS:
            return {
                ...state,
                deleting: false
            }
        default:
            return state;
    }
}

import { LocalStorageService } from "./LocalStorageService"

//import supported translations
import { en } from '../translations';
import { updateIntl } from 'react-intl-redux'

const Langages = Object.freeze({
  ENG: 1,
  RUS: 2,
  TR: 3,
  ES: 4,
  RO: 5
});

/** LanguageService namespace
 * @namespace
 */
export const LanguageService = {

  defaultLang: 'en',

  supportedLanguges: {
    'en': { id: Langages.ENG, name: "English", key: 'en', code: "us", translations: en }
  },

  /**Get selected language*/
  getSelectedLanguage: function () {

    const selectedLang = LocalStorageService.get('selected_language');
    if (selectedLang) {
      return selectedLang;
    }
    return this.defaultLang;
  },

  /**Set selected language
  * @param {string} langCode - The language code.
  */
  setSelectedLanguage: function (langCode) {

    if (this.supportedLanguges[langCode]) {
      LocalStorageService.set("selected_language", langCode);
    }
  },

  changeLanguage: (dispatch, key) => {
    LanguageService.setSelectedLanguage(key);
    const translation = LanguageService.getTranslation(key);
    dispatch(updateIntl(translation));
  },

  /**Get translation by language code
  * @param {string} langCode - The language code.
  */
  getTranslation: function (langCode) {
    if (langCode) {
      if (langCode !== this.defaultLang) {
        this.supportedLanguges[langCode].translations.messages = { ...this.supportedLanguges[this.defaultLang].translations.messages, ...this.supportedLanguges[langCode].translations.messages }
      }
      return this.supportedLanguges[langCode].translations;
    }
    return this.supportedLanguges[this.defaultLang].translations;
  }
}
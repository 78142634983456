import {ActionType} from '../../../constants/ActionTypes'
export default function captchaReducer(state = {}, action = {}) {
    switch (action.type) {
        case ActionType.GET_PROJECT_CAPTCHA_PROVIDERS_SUCCESS:
            return {
                ...state,
                data: action.payload,
                inLoadingProcess: false
            }
        case ActionType.GET_PROJECS_CAPTCHA_PROVIDERS_ERROR:
            return {
                ...state,
                inLoadingProcess: false
            }
        case ActionType.GET_CAPTCHA_BEGIN:
            return {
                ...state,
                inSavingProcess: false,
                inLoadingProcess: true
            }
        case ActionType.SET_CAPTCHA_BEGIN:
            return {
                ...state,
                inSavingProcess: true
            }
        case ActionType.SET_PROJECTS_CAPTCHA_ERROR:
            return {
                ...state,
                error: action.payload,
                inSavingProcess: false
            }
        case ActionType.SET_PROJECTS_CAPTCHA_SUCCESS:
            return {
                ...state,
                inSavingProcess: false
            }
        default:
            return state;
    }
}

import { ActionType } from '../../constants/ActionTypes'

export default function projectsReducer(state = {}, action = {}) {
    switch (action.type) {

        //Get Projects
        case ActionType.GET_PROJECTS_BEGIN:
        case ActionType.GET_PROJECT_BEGIN:
        case ActionType.DELETE_PROJECT_BEGIN:
        case ActionType.CHANGE_PROJECT_STATUS_BEGIN:
        case ActionType.GET_COMPANIES_BEGIN:
            return {
                ...state,
                inProcess: true
            }
        case ActionType.GET_PROJECTS_SUCCESS:
            return {
                ...state,
                allProjects: action.payload,
                inProcess: false
            }
        case ActionType.GET_PROJECTS_ERROR:
        case ActionType.GET_PROJECT_ERROR:
            return {
                ...state,
                inProcess: false
            }
        case ActionType.GET_PROJECT_SUCCESS:
            return {
                ...state,
                selectedProject: action.payload,
                inProcess: false
            }
        case ActionType.RESET_SELECTED_PROJECT:
            return {
                ...state,
                selectedProject: {}
            }
        //Create Project
        case ActionType.CREATE_PROJECT_BEGIN:
            return {
                ...state,
                inSavingProcess: true,

            }
        case ActionType.CREATE_PROJECT_SUCCESS:
            return {
                ...state,
                inSavingProcess: false
            }
        case ActionType.CREATE_PROJECT_ERROR:
            return {
                ...state,
                inSavingProcess: false,
                newProject: {
                    ...state.newProject,
                    error: action.payload
                }
            }
        case ActionType.RESET_PROJECT_DATA:
            return {
                ...state,
                inProcess: false,
                newProject: {
                    inProcess: false,
                    error: null,
                    currentStep: 0,
                    name: '',
                    templateId: 1
                }
            }

        //Delete Project
        case ActionType.DELETE_PROJECT_SUCCESS:

            let updatedProjects = Object.keys(state.allProjects).reduce((r, e) => {
                if (Number(e) !== action.payload) r[e] = state.allProjects[e];
                return r
            }, {})

            return {
                ...state,
                inProcess: false,
                allProjects: updatedProjects
            }
        case ActionType.DELETE_PROJECT_ERROR:
        case ActionType.CHANGE_PROJECT_STATUS_ERROR:
            return {
                ...state,
                inProcess: false,
                error: action.payload
            }
        //Change Project status
        case ActionType.CHANGE_PROJECT_STATUS_SUCCESS:
            return {
                ...state,
                inProcess: false,
            }
        //Temp project data
        case ActionType.SET_NEW_PROJECT_NAME:
            return {
                ...state,
                newProject: {
                    ...state.newProject,
                    name: action.payload
                }
            }
        case ActionType.SET_NEW_PROJECT_TEMPLATE_ID:
            return {
                ...state,
                newProject: {
                    ...state.newProject,
                    templateId: action.payload
                }
            }
        case ActionType.SET_PROJECTS_STEP_PROGRESS:
            return {
                ...state,
                newProject: {
                    ...state.newProject,
                    currentStep: action.payload
                }
            }
        case ActionType.GET_COMPANIES_SUCCESS:
            return {
                ...state,
                companies: action.payload,
                inProcess: false
            }
        case ActionType.GET_COMPANIES_ERROR:
            return {
                ...state,
                error: action.payload,
                inProcess: false
            }

        case ActionType.GET_ALL_COMPANIES_BEGIN:
        case ActionType.ADD_COMPANY_BEGIN:
        case ActionType.DELETE_COMPANY_BEGIN:
            return {
                ...state,
                loading: true
            }
        case ActionType.GET_ALL_COMPANIES_SUCCESS:
            return {
                ...state,
                allCompanies: action.payload.companies,
                count: action.payload.count,
                loading: false
            }
        case ActionType.GET_ALL_COMPANIES_ERROR:
            return {
                ...state,
                error: action.payload,
                loading: false
            }
        case ActionType.ADD_COMPANY_ERROR:
        case ActionType.DELETE_COMPANY_ERROR:
        case ActionType.ADD_COMPANY_SUCCESS:
        case ActionType.DELETE_COMPANY_SUCCESS:
            return {
                ...state,
                loading: false
            }
        case ActionType.CHANGE_PROJECS_NAME_BEGIN:
        case ActionType.CHANGE_COMPANY_NAME_BEGIN:
            return {
                ...state,
                inProcess: true
            }
            
        case ActionType.CHANGE_PROJECS_NAME_SUCCESS:
        case ActionType.CHANGE_COMPANY_NAME_SUCCESS:
            return {
                ...state,
                inProcess: false
            }
            
        case ActionType.CHANGE_PROJECS_NAME_ERROR:
        case ActionType.CHANGE_COMPANY_NAME_ERROR:
            return {
                ...state,
                inProcess: false
            }
        default:
            return state;
    }
}

import { ActionType } from '../../constants/ActionTypes';
import config from '../../constants/Configs';
import { Constants } from '../../constants/Constants';
import axios from 'axios';
import { message } from "antd";

//Get Projects
export const getProjectsBegin = () => ({
    type: ActionType.GET_PROJECTS_BEGIN,
});

export const getProjectsSuccess = projects => ({
    type: ActionType.GET_PROJECTS_SUCCESS,
    payload: projects
});

export const getProjectsError = error => ({
    type: ActionType.GET_PROJECTS_FAILURE,
    payload: { error }
});

//Get Project by id
export const getProjectBegin = () => ({
    type: ActionType.GET_PROJECT_BEGIN,
});

export const getProjectSuccess = projects => ({
    type: ActionType.GET_PROJECT_SUCCESS,
    payload: projects
});

export const resetSelectedProject = () => ({
    type: ActionType.RESET_SELECTED_PROJECT
});

export const getProjectError = error => ({
    type: ActionType.GET_PROJECT_ERROR,
    payload: { error }
});

export const getTemplateBegin = () => ({
    type: ActionType.GET_TEMPLATES_BEGIN,
});

export const getTemplatesSuccess = templates => ({
    type: ActionType.GET_TEMPLATES_SUCCESS,
    payload: templates
});

export const getTemplatesError = error => ({
    type: ActionType.GET_TEMPLATES_FAILURE,
    payload: { error }
});


//Check project name
export const checkProjectNameBegin = () => ({
    type: ActionType.CHECK_PROJECT_NAME_BEGIN,
});

export const checkProjectNameSuccess = (data) => ({
    type: ActionType.CHECK_PROJECT_NAME_SUCCESS,
    payload: data
});

export const checkProjectNameError = (data) => ({
    type: ActionType.CHECK_PROJECT_NAME_ERROR,
    payload: data
});


//Create Project
export const createProjectBegin = () => ({
    type: ActionType.CREATE_PROJECT_BEGIN,
});

export const createProjectSuccess = (data) => ({
    type: ActionType.CREATE_PROJECT_SUCCESS,
    payload: data
});
export const createProjectError = (data) => ({
    type: ActionType.CREATE_PROJECT_ERROR,
    payload: data
});

//Delete Project
export const deleteProjectBegin = () => ({
    type: ActionType.DELETE_PROJECT_BEGIN
});

export const deleteProjectSuccess = (data) => ({
    type: ActionType.DELETE_PROJECT_SUCCESS,
    payload: data
});

export const deleteProjectError = (data) => ({
    type: ActionType.DELETE_PROJECT_ERROR,
    payload: data
});


//Change Project Status
export const changeProjectStatusBegin = () => ({
    type: ActionType.CHANGE_PROJECT_STATUS_BEGIN
});

export const changeProjectStatusSuccess = (data) => ({
    type: ActionType.CHANGE_PROJECT_STATUS_SUCCESS,
    payload: data
});

export const changeProjectStatusError = (data) => ({
    type: ActionType.CHANGE_PROJECT_STATUS_ERROR,
    payload: data
});

//Get Projects Info
export const getProjectSettingsInfoBegin = () => ({
    type: ActionType.GET_PROJECS_SETTINGS_INFO_BEGIN,
});

export const getProjectSettingsInfoSuccess = (data) => ({
    type: ActionType.GET_PROJECS_SETTINGS_INFO_SUCCESS,
    payload: data
});

export const getProjectSettingsInfoError = (data) => ({
    type: ActionType.GET_PROJECS_SETTINGS_INFO_ERROR,
    payload: data
});

//Change Project Name
export const changeProjectNameBegin = () => ({
    type: ActionType.CHANGE_PROJECS_NAME_BEGIN,
});

export const changeProjectNameSuccess = () => ({
    type: ActionType.CHANGE_PROJECS_NAME_SUCCESS,
});

export const changeProjectNameError = (data) => ({
    type: ActionType.CHANGE_PROJECS_NAME_ERROR,
    payload: data
});

//Change cloud provider

export const changeCloudProviderBegin = () => ({
    type: ActionType.CHANGE_CLOUD_BEGIN,
});

export const changeCloudProviderSuccess = () => ({
    type: ActionType.CHANGE_CLOUD_SUCCESS,
});

export const changeCloudProviderError = (data) => ({
    type: ActionType.CHANGE_CLOUD_ERROR,
    payload: data
});

export function getProjects(params) {
    return dispatch => {

        //Show preloader
        dispatch(getProjectsBegin());

        return axios({
            url: config.BASE_URL + '/Project/GetProjects',
            method: 'GET',
            params
        })
            .then(response => dispatch(getProjectsSuccess(response.data)))
            .catch((error) => {
                // Status looks bad
                dispatch(getProjectsError(error.response && error.response.data));
                return error.response && error.response.data;
            });
    };
}

export function getProject(projectId) {
    return dispatch => {

        //Show preloader
        dispatch(getProjectBegin());

        return axios({
            url: config.BASE_URL + '/Project/GetProject?projectId=' + projectId,
            method: 'GET'
        })
            .then(response => {

                dispatch(getProjectSuccess(response.data))
                return response.data;
            })
            .catch((error) => {
                // Status looks bad
                dispatch(getProjectError(error.response && error.response.data));
                return error.response && error.response.data;
            });
    };
}

export function checkProjectName() {
    return dispatch => {

        dispatch(checkProjectNameBegin());

        return axios({
            url: config.BASE_URL + '/Project/CheckName',
            method: 'POST'
        })
            .then(response => dispatch(checkProjectNameSuccess(response.data)))
            .catch((error) => {
                dispatch(checkProjectNameError(error.response && error.response.data));
            });
    };
}


export function getTemplates() {

    return dispatch => {

        dispatch(getTemplateBegin());
        return axios({
            url: config.BASE_URL + '/Templates/GetTemplates',
            method: 'GET'
        })
            .then(response => dispatch(getTemplatesSuccess(response.data)))
            .catch((error) => {
                dispatch(getTemplatesError(error.response && error.response.data));
            });
    };
}


export function deleteProject(data) {
    const projectId = data.projectId;

    return dispatch => {
        const params = {
            id: projectId,
            password: data.password
        };
        const CompanyId = data.companyId;

        // const projects = this.state.projects.projects.filter(prop => prop.Id !== projectId);
        dispatch(deleteProjectBegin());
        return axios({
            url: `${config.BASE_URL}/Project/Delete`,
            method: 'POST',
            data: JSON.stringify(params),
            headers: {
                CompanyId,
                ProjectId: projectId
            },
        })
            .then(response => dispatch(deleteProjectSuccess(params)))
            .catch((error) => {
                dispatch(deleteProjectError({ data: error.response && error.response.data, params }));
                return "error"
            });
    };
}
export function changeProjectTemplate(data) {
    const projectId = data.projectId;

    return dispatch => {
        const params = {
            templateId: data.templateId,
            password: data.password
        };
        const CompanyId = data.companyId;

        dispatch(changeProjectStatusBegin());
        return axios({
            url: `${config.BASE_URL}/Project/ChangeProjectTemplate`,
            data: JSON.stringify(params),
            method: 'POST',
            headers: {
                CompanyId,
                ProjectId: projectId
            },
        })
            .then(response => dispatch(changeProjectStatusSuccess(params)))
            .catch((error) => {
                dispatch(changeProjectStatusError({ data: error.response && error.response.data, params }));
                return "error"
            });
    };
}

export function changeProjectStatus(data, intl) {
    const projectId = data.projectId;
    return dispatch => {
        const params = {
            state: data.state,
            projectId,
            password: data.password
        };

        const CompanyId = data.companyId;

        dispatch(changeProjectStatusBegin());
        return axios({
            url: `${config.BASE_URL}/Project/ChangeProjectStatus`,
            method: 'POST',
            data: JSON.stringify(params),
            headers: {
                CompanyId,
                ProjectId: projectId
            },
        })
            .then(response => {
                message.success(intl.messages.status_has_changed);
                return dispatch(changeProjectStatusSuccess(params));
            })
            .catch((error) => {
                dispatch(changeProjectStatusError({ data: error.response && error.response.data, params }));
                return "error";
            });
    };
}

export function createProject(project, CompanyId/*, history*/) {
    return dispatch => {
        dispatch(createProjectBegin());
        return axios({
            url: config.BASE_URL + '/Project/CreateNewProject',
            method: 'POST',
            data: JSON.stringify(project),
            headers: {
                CompanyId
            },
        })
            .then(response => dispatch(createProjectSuccess(response.data)))
            .catch((error) => {
                dispatch(createProjectError(error.response && error.response.data));
            });
    };
}

//Get project settings info
export function getProjectSettingsInfo(projectId) {
    return dispatch => {
        dispatch(getProjectSettingsInfoBegin());
        const params = `?projectId=${projectId}`;
        return axios({
            url: config.BASE_URL + '/Project/GetProjectInfo' + params,
            method: Constants.GET,
            heasers: {
                ProjectId: projectId
            }
        })
            .then(response => dispatch(getProjectSettingsInfoSuccess(response.data)))
            .catch((error) => {
                dispatch(getProjectSettingsInfoError(error.response && error.response.data));
            });
    };
}

export function changeProjectName({ projectId, projectName }) {
    return dispatch => {
        dispatch(changeProjectNameBegin());

        return axios({
            url: config.BASE_URL + '/Project/RenameProject',
            method: Constants.POST,
            data: {
                Id: projectId,
                Name: projectName
            }
        })
            .then(() => {
                dispatch(changeProjectNameSuccess())
            })
            .catch((error) => {
                dispatch(changeProjectNameError(error.response && error.response.data));
            });
    };
}

export function getCloudProvider() {
    return () => {
        return axios({
            url: config.BASE_URL + '/Project/CloudProvider',
            method: Constants.GET,
        })
            .then((response) => {
                return response;
            })
    };
}

export function changeCloudProvider(provider) {
    return dispatch => {
        dispatch(changeCloudProviderBegin())
        return axios({
            url: config.BASE_URL + '/Project/CloudProvider',
            method: Constants.PUT,
            params: { cloudProvider: provider }
        })
            .then((data) => {
                dispatch(changeCloudProviderSuccess(data))
                return data;
            }).catch((error) => {
                dispatch(changeCloudProviderError(error.response && error.response.data))
            });
    };
}
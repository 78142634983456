import { ActionType } from '../../constants/ActionTypes';
import { ErrorType } from '../../constants/Errors/ErrorTypes';
import { RestPath } from '../../constants/SitePaths';
import config from '../../constants/Configs';
import { message } from 'antd';
import axios from 'axios';

// Not verified account 
export const notVerifiedAccount = (code) => ({
  type: ActionType.NOT_VERIFIED_ACCOUNT,
  payload: code
});

// Account Email Verification
export const verifyAccountEmailBegin = () => ({
  type: ActionType.VERIFY_ACCOUNT_EMAIL_BEGIN
});

export const verifyAccountEmailSuccess = user => ({
  type: ActionType.VERIFY_ACCOUNT_EMAIL_SUCCESS,
  payload: { user }
});

export const verifyAccountEmailError = error => ({
  type: ActionType.VERIFY_ACCOUNT_EMAIL_FAILURE,
  payload: { error }
});

// Resend Email Verification Code
export const resendEmailVerificationCodeBegin = () => ({
  type: ActionType.RESEND_EMAIL_VERIFICATION_CODE_BEGIN
});

export const resendEmailVerificationCodeSuccess = user => ({
  type: ActionType.RESEND_EMAIL_VERIFICATION_CODE_SUCCESS
});

export const resendEmailVerificationCodeError = error => ({
  type: ActionType.RESEND_EMAIL_VERIFICATION_CODE_FAILURE,
  payload: { error }
});


// User Mobile Verification
export const verifyAccountMobileBegin = () => ({
  type: ActionType.VERIFY_ACCOUNT_MOBILE_BEGIN
});

export const verifyAccountMobileSuccess = user => ({
  type: ActionType.VERIFY_ACCOUNT_MOBILE_SUCCESS,
  payload: { user }
});

export const verifyAccountMobileError = error => ({
  type: ActionType.VERIFY_ACCOUNT_MOBILE_FAILURE,
  payload: { error }
});


// Resend Mobile Verification Code
export const resendMobileVerificationCodeBegin = () => ({
  type: ActionType.RESEND_MOBILE_VERIFICATION_CODE_BEGIN
});

export const resendMobileVerificationCodeSuccess = user => ({
  type: ActionType.RESEND_MOBILE_VERIFICATION_CODE_SUCCESS
});

export const resendMobileVerificationCodeError = error => ({
  type: ActionType.RESEND_MOBILE_VERIFICATION_CODE_FAILURE,
  payload: { error }
});

/* Account email phone verification */
export function verifyAccountEmail(paramameters) {
  return dispatch => {

    dispatch(verifyAccountEmailBegin());

    const params = {
      userName: paramameters.email,
      VerificationCode: paramameters.verification_code
    };

    return axios({
      url: config.BASE_URL + RestPath.VERIFY_EMAIL,
      method: 'POST',
      data:JSON.stringify(params)
    })
      .then(response => {
        message.success('Email successfully verified.');
        switch (paramameters.code) {
          case ErrorType.EMAIL_AND_MOBILE_NOT_VERIFIED:
            dispatch(notVerifiedAccount(ErrorType.MOBILE_NOT_VERIFIED));
            break;
          default:
            dispatch(notVerifiedAccount(ErrorType.JUST_VERIFIED));
            break;
        }
        dispatch(verifyAccountEmailSuccess(response.data));
      })
      .catch((error) => {
        dispatch(verifyAccountEmailError(error.response && error.response.data));
      });
  };
}


/* Resend email verification code*/
export function resendEmailVerificationCode(email, messages) {
  return dispatch => {

    dispatch(resendEmailVerificationCodeBegin());
    const params = {
      userName: email
    };

    return axios({
      url: config.BASE_URL + RestPath.RESEND_EMAIL_VERIFICATION,
      method: 'POST',
      data:JSON.stringify(params)
    })
      .then(response => {
        message.success(messages.verification_code_resent);
        dispatch(resendEmailVerificationCodeSuccess());
      })
      .catch((error) => {
        dispatch(resendEmailVerificationCodeError(error.response && error.response.data));
      });
  };
}

/* User mobile phone verification */
export function verifyAccountMobile(paramamets) {
  return dispatch => {

    dispatch(verifyAccountMobileBegin());

    const params = {
      userName: paramamets.email,
      verificationCode: paramamets.verification_code
    };


    return axios({
      url: config.BASE_URL + RestPath.VERIFY_MOBILE,
      method: 'POST',
      data:JSON.stringify(params)
    })
      .then(response => {
        switch (paramamets.code) {
          case ErrorType.EMAIL_AND_MOBILE_NOT_VERIFIED:
            dispatch(notVerifiedAccount(ErrorType.MOBILE_NOT_VERIFIED));
            break;
          default:
            dispatch(notVerifiedAccount(ErrorType.JUST_VERIFIED));
            break;
        }
        dispatch(verifyAccountMobileSuccess(response.data));
      })
      .catch((error) => {
        dispatch(verifyAccountMobileError(error.response && error.response.data));
      });
  };
}


/* Resend mobile verification code*/
export function resendMobileVerificationCode(parameters, messages) {
  return dispatch => {

    dispatch(resendMobileVerificationCodeBegin());

    const params = {
      UserName: parameters.email,
      MobileNumber: parameters.prefix + parameters.phone,
    };

    return axios({
      url: config.BASE_URL + RestPath.RESEND_MOBILE_VERIFICATION,
      method: 'POST',
      data:JSON.stringify(params)
    })
      .then(response => {
        message.success(messages.verification_code_resent);
        dispatch(resendMobileVerificationCodeSuccess());
      })
      .catch((error) => {
        dispatch(resendMobileVerificationCodeError(error.response && error.response.data));

      });
  };
}


